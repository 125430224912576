import React, { PropsWithChildren, useCallback } from "react";
import { TailSpin as Loader } from "react-loader-spinner";

import client, { setClientToken } from "../utils/client";
import { translations } from "@aws-amplify/ui-react";
import { AmplifyUser } from "@aws-amplify/ui";
import { I18n, Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import "amazon-cognito-passwordless-auth/passwordless.css";

import {
  usePasswordless,
  PasswordlessContextProvider,
} from "amazon-cognito-passwordless-auth/react";
import { Passwordless } from "amazon-cognito-passwordless-auth";
import { CognitoIdTokenPayload } from "amazon-cognito-passwordless-auth/jwt-model";
import {
  Passwordless as PasswordlessComponent,
  Fido2Toast,
} from "components/Passwordless/Components";

Passwordless.configure({
  clientId: process.env.REACT_APP_COGNITO_PW_CLIENT_ID!,
  cognitoIdpEndpoint: process.env.REACT_APP_COGNITO_REGION!,
  // debug: console.trace,
  fido2: {
    baseUrl: process.env.REACT_APP_FIDO_AUTH_URL!,
    authenticatorSelection: {
      userVerification: "discouraged",
    },
  },
});

interface IAuthContext {
  user?: AmplifyUser;
  token: string;
  isAuthenticated: boolean;
  logout: Function;
  refreshSession: Function;
  getUserInfo: () => UserInfo;
}

export const AuthContext = React.createContext<IAuthContext>({
  token: "",
  isAuthenticated: false,
  logout: () => {},
  refreshSession: () => {},
  getUserInfo: () => ({} as UserInfo),
});

export const AuthProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const dict = {
    ...translations,
    de: {
      ...translations.de,
      "Enter your Username": "Geben Sie Ihre E-Mail Adresse ein",
    },
  };

  I18n.putVocabularies(dict);
  I18n.setLanguage("de");

  return (
    <PasswordlessContextProvider enableLocalUserCache={true}>
      <BehindTheScenes user={{} as any} logout={() => {}}>
        {children}
      </BehindTheScenes>
    </PasswordlessContextProvider>
  );
};

interface BehindTheScenesProps {
  user: AmplifyUser;
  logout: Function;
}

type TokenWithCustomClaims = CognitoIdTokenPayload & {
  "custom:accountId": string;
};

type UserInfo = CognitoIdTokenPayload & { accountId: string };

const BehindTheScenes = (props: PropsWithChildren<BehindTheScenesProps>) => {
  const {
    signOut,
    signInStatus,
    tokens,
    tokensParsed,
    refreshTokens,
    isRefreshingTokens,
  } = usePasswordless();

  const logout = signOut;
  const token = tokens?.idToken || "";

  const isAuthenticated = signInStatus === "SIGNED_IN";
  const isPending = signInStatus === "CHECKING";

  setClientToken(token);

  const refreshSession = useCallback(() => {
    if (isRefreshingTokens) return;
    const now = Date.now() / 1000;

    const expiration = tokensParsed?.expireAt.getTime() || 0;

    if (expiration && now < expiration) {
      return;
    }

    refreshTokens();
  }, [tokensParsed, refreshTokens, isRefreshingTokens]);

  const getUserInfo = useCallback(() => {
    const token = tokensParsed?.idToken as TokenWithCustomClaims;

    return { ...token, accountId: token["custom:accountId"] };
  }, [tokensParsed]);

  React.useEffect(() => {
    (async () => {
      const timeout = process.env.NODE_ENV !== "production" ? 2000 : 0;

      setTimeout(() => {
        if (!token) return;

        client.post("/users");
      }, timeout);
    })();
  }, [token]);

  if (isPending) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader color="#0991b1" height={100} width={100} />
      </div>
    );
  }

  return (
    <PasswordlessComponent>
      <AuthContext.Provider
        value={{
          user: undefined,
          getUserInfo,
          token,
          isAuthenticated,
          logout,
          refreshSession,
        }}
      >
        {props.children}
      </AuthContext.Provider>
      <Fido2Toast />
    </PasswordlessComponent>
  );
};
