import React, { useState, useEffect } from "react";
import { BasePage } from "components/BasePage/BasePage";
import { JsonEditor } from "components/inputs/JsonEditor";
import { useClient } from "utils/client";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ControlContext } from "context/Controls";
import { isDev, isLocal } from "context/FeatureFlag";

// Define the User type based on the API response
interface User {
  sub: string;
  email: string;
  accountId: string;
}

export const FeatureFlagsBackOffice = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { displayNotification } = React.useContext(ControlContext);

  const [error, setError] = useState<string | null>(null);

  // Fetch feature flags
  const { data: featureFlags, isLoading: isLoadingFlags } = useQuery(
    "featureflags",
    async () => {
      try {
        const res = await client.get("/featureflags");
        return res.data;
      } catch (err) {
        setError("Failed to load feature flags");
        throw err;
      }
    }
  );

  // Update feature flags
  const { mutate: updateFeatureFlags, isLoading: isSaving } = useMutation(
    async (updatedFlags: any) => {
      try {
        const res = await client.post("/featureflags", updatedFlags);
        return res.data;
      } catch (err) {
        setError("Failed to update feature flags");
        throw err;
      }
    },
    {
      onSuccess: () => {
        // Invalidate and refetch the feature flags query
        queryClient.invalidateQueries("featureflags");
        displayNotification!("Feature flags updated", "success");
        setError(null);
      },
    }
  );

  const handleSave = (updatedFlags: any) => {
    updateFeatureFlags(updatedFlags);
  };

  // Fetch users from the /cognito endpoint
  const { data: users, isLoading: isLoadingUsers } = useQuery(
    "cognito-users",
    async () => {
      try {
        const res = await client.get("/cognito");
        return res.data as User[];
      } catch (err) {
        setError("Failed to load users");
        throw err;
      }
    }
  );

  // State for search input and filtered users
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  // Filter users based on search term
  useEffect(() => {
    if (!users) {
      setFilteredUsers([]);
      return;
    }

    if (searchTerm.trim() === "") {
      setFilteredUsers(users);
      return;
    }

    const lowerSearchTerm = searchTerm.toLowerCase();
    const filtered = users.filter(
      (user) =>
        user.email.toLowerCase().includes(lowerSearchTerm) ||
        user.sub.toLowerCase().includes(lowerSearchTerm) ||
        user.accountId.toLowerCase().includes(lowerSearchTerm)
    );
    setFilteredUsers(filtered);
  }, [searchTerm, users]);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Handle user selection
  const handleUserSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const userId = e.target.value;
    if (!userId) {
      setSelectedUser(null);
      return;
    }

    const user = users?.find((u) => u.sub === userId) || null;
    setSelectedUser(user);
    console.log("Selected user:", user);
  };

  return (
    <BasePage
      breadcrumbItems={[
        { label: "Backoffice", location: "/_backoffice" },
        { label: "Feature Flags", location: "/_backoffice/_featureflags" },
      ]}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900 mb-6">
          Feature Flags Configuration{" "}
          <span className="text-red-600">DANGER ZONE!</span>
        </h1>

        <p className="text-sm text-gray-500 mb-4">
          <span className="text-red-500 font-bold">
            Du befindest dich auf der <b>{isDev || isLocal ? "DEV" : "PROD"}</b>
            -Umgebung
          </span>
        </p>

        <div className="mb-8">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            Cognito Users
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Du kannst alle User im Certain System suchen. Für die Feature Flags
            benötigst du die "ID". Dazu wählst du entweder einen User direkt aus
            der Liste <b>oder</b> du gibst erst einen Filterbegriff ein.{" "}
            <b>Beachte, dass die Emails maskiert sind!</b>
          </p>

          {isLoadingUsers ? (
            <div className="text-center py-12">
              <p className="text-gray-500">Loading users...</p>
            </div>
          ) : (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                {error && (
                  <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg
                          className="h-5 w-5 text-red-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-red-700">{error}</p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mb-4">
                  <label
                    htmlFor="user-search"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Search Users
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      name="user-search"
                      id="user-search"
                      className="focus:ring-cyan-500 focus:border-cyan-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                      placeholder="Search by email or ID"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="user-select"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Select User
                  </label>
                  <select
                    id="user-select"
                    name="user-select"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm rounded-md"
                    value={selectedUser?.sub || ""}
                    onChange={handleUserSelect}
                  >
                    <option value="">Select a user</option>
                    {filteredUsers.map((user) => (
                      <option key={user.sub} value={user.sub}>
                        {user.email} ({user.accountId} | {user.sub})
                      </option>
                    ))}
                  </select>
                </div>

                {selectedUser && (
                  <div className="mt-4 p-4 bg-gray-50 rounded-md">
                    <h3 className="text-lg font-medium text-gray-900">
                      Selected User
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        <span className="font-medium">Email:</span>{" "}
                        {selectedUser.email}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-medium">Sub:</span>{" "}
                        {selectedUser.sub}
                      </p>
                      <p className="text-sm text-gray-500">
                        <span className="font-medium text-cyan-600">
                          Account-ID:
                        </span>{" "}
                        {selectedUser.accountId}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        {isLoadingFlags ? (
          <div className="text-center py-12">
            <p className="text-gray-500">Loading feature flags...</p>
          </div>
        ) : (
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <p className="text-sm text-gray-500 mb-4">
                Solltest du dir unsicher sein, lass es besser!
              </p>
              <p className="text-sm text-gray-500 mb-4">
                Die hier gespeicherten Änderungen werden die Feature-Flags{" "}
                <u>sofort</u> für die User dieser Umgebung verändern. D.h.
                Features werden sichtbar oder unsichtbar unmittelbar nach dem
                Speichern.
              </p>
              <JsonEditor
                defaultValue={featureFlags}
                onSave={handleSave}
                isLoading={isSaving}
              />
            </div>
          </div>
        )}
      </div>
    </BasePage>
  );
};
