import React, { useState, useEffect } from "react";
import { PrimaryButton } from "components/Button/PrimaryButton";

interface JsonEditorProps {
  defaultValue: any;
  onSave: (value: any) => void;
  isLoading?: boolean;
}

export const JsonEditor: React.FC<JsonEditorProps> = ({
  defaultValue,
  onSave,
  isLoading = false,
}) => {
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (defaultValue) {
      try {
        setValue(JSON.stringify(defaultValue, null, 2));
        setError(null);
      } catch (err) {
        setError("Invalid JSON provided");
      }
    }
  }, [defaultValue]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    setError(null);
  };

  const handleSave = () => {
    try {
      const parsedValue = JSON.parse(value);
      onSave(parsedValue);
    } catch (err) {
      setError("Invalid JSON format");
    }
  };

  return (
    <div className="bg-white rounded-md shadow relative overflow-hidden">
      <div className="p-4">
        <textarea
          value={value}
          onChange={handleChange}
          className={`w-full h-[60vh] font-mono text-sm p-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            error ? "border-red-500" : "border-gray-300"
          }`}
          spellCheck={false}
        />
        {error && <div className="text-red-500 mt-2">{error}</div>}
        <div className="mt-4 flex justify-end">
          <PrimaryButton onClick={handleSave} disabled={isLoading}>
            {isLoading ? "Speichert..." : "Feature Flags aktualisieren"}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
