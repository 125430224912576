import { useQuery } from "react-query";
import React, { PropsWithChildren, useCallback, useContext } from "react";

import { AuthContext } from "./Auth";
import { useClient } from "utils/client";

export enum Feature {
  OnTheFlyTranslations = "OnTheFlyTranslations",
  EmvDirectiveSearch = "EmvDirectiveSearch",
  InstructionManual = "InstructionManual",
  AdditionalLang = "AdditionalLang",
  PowerSearch = "PowerSearch",
}

interface IFeatureFlagContext {
  isFeatureEnabled: (feature: Feature, accountId?: string) => boolean;
}

export const FeatureFlagContext = React.createContext<
  Partial<IFeatureFlagContext>
>({});

export const isDev =
  process.env.REACT_APP_APP_HOST !== "https://app.certain-cloud.de";
export const isLocal =
  process.env.REACT_APP_APP_HOST === "http://localhost:3000";

const staticFeatureFlags: Record<Feature, boolean> = {
  [Feature.OnTheFlyTranslations]: isDev,
  [Feature.EmvDirectiveSearch]: isDev,
  [Feature.InstructionManual]: isDev,
  [Feature.AdditionalLang]: false,
  [Feature.PowerSearch]: false,
};

const accountFeatureFlags: Record<Feature, string[]> = {
  [Feature.OnTheFlyTranslations]: [],
  [Feature.AdditionalLang]: [],
  [Feature.EmvDirectiveSearch]: [],
  [Feature.InstructionManual]: [
    "2iy81ZAzxrTgaFTNsouG33h3TOL", // al@arm*** @p
    "2hheNHpbgTb3UbqtqyBHFJuG1wK", // f.s@c*** @p
    "2YZmfcgHEwRqfhDhzBEfzRYsN8s", // b.f@c*** @p
    "2Y75iNh1rxmzHNXFVQe4HbDqU1u", // n.f@c*** @p
    "2hmUfgqIrcy3veoejK0kdS4rajy", // info@c*** @p
    "2q1CbYD1RyTOBx761vBHIMX0APK", // j.e@c*** @p
    "2d2MiFrppkg0Z2FzkHYOKmy6GFM", // d.d@she*** @p
    "2iKY8knAFNGLtMjelIOuhlTWWuV", // l.k@k*** @p
    "2pkPMTfw7xz0qNWwS3emd05p8Ek", // t.f@k*** @p
    "2mG24Nqd6Nz15mGIwUekQeS9Q4S", // w.m@ma**-c**.de @p
    "2nttDzR3kcAI2mlnlNjGZkxnvPI", // inf@i**-m**.de @p
    "2nZEtzxqXDa0mnr85pfXMOnVJMB", // m.m@ew***.de @p
    "2sKpOadg3R50Mdj1oQTEO6hCnnf", // j.c@h**-a***.de @p
    "2sZJxkHfxniYUWeIvozbhWKompv", // e.s@aes-***.com @p
    "2sXhEU5PVwSaOIEEQzMIlQT5ysz", // j*@ho***.de @p
    "2hodkeq6WWLACGBy8z6zRgPU3qZ", // h**.h**@k***.de @p
    "2swdE4nTtIGfKR9SVKpKWS8cg0E", // m**.r**@k***.de @p
    "2sywoeMYYiWZDgWmA2zHL01w3wR", // m**.f**@m***.de @p
    "2t7eIL8oAzCKOYYBoEcj8M7fv8x", // b**.s**@as*-**.ch @p
    "2t7xyPCLmQQFQkTELHuH9w6JGnU", // i**@ma**-ce.de @p
    "2tDwcrmDIzTX24r2VyvhjtI2zaj", // t**.n**@t***.de @p
    "2jeW3XVRGaYUXTDVii5lEKhLsn3", // j.e***@en-p**.org @p
    "2tnO2UoAzha4Y0flXJwsU7hwbE6", // k**@aa*-s**.de @p
    "2tweNr0ma3i59i0yAzPzTjBXQt8", // j.d**@w**-g**.de @p
  ],
  [Feature.PowerSearch]: [],
};

export const FeatureFlagProvider: React.FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const apiFeatureFlags = useFetchFeatureFlags();
  const { getUserInfo } = useContext(AuthContext);

  const isFeatureEnabled = useCallback(
    (feature: Feature, accountId?: string) => {
      if (accountId || getUserInfo().accountId) {
        const accountIdToCheck = accountId || getUserInfo().accountId;

        const isEnabledViaStaticFlag = !!staticFeatureFlags[feature];
        const isEnabledViaStaticAccountFlag =
          accountFeatureFlags[feature].includes(accountIdToCheck);
        const isEnabledViaApiFlag = isAccountIdInApiFeatureFlags(
          feature,
          accountIdToCheck,
          apiFeatureFlags
        );

        return (
          isEnabledViaStaticFlag ||
          isEnabledViaStaticAccountFlag ||
          isEnabledViaApiFlag
        );
      }

      return !!staticFeatureFlags[feature];
    },
    [apiFeatureFlags, getUserInfo]
  );

  return (
    <FeatureFlagContext.Provider value={{ isFeatureEnabled }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

type ApiFeatureFlags = Record<Feature, boolean | string[]>;

const isAccountIdInApiFeatureFlags = (
  feature: Feature,
  accountId: string,
  apiFeatureFlags?: ApiFeatureFlags
) => {
  if (!apiFeatureFlags?.[feature]) {
    return false;
  }

  if (typeof apiFeatureFlags[feature] === "boolean") {
    return apiFeatureFlags[feature] as boolean;
  }

  if (Array.isArray(apiFeatureFlags[feature])) {
    return apiFeatureFlags[feature].includes(accountId);
  }

  return false;
};

const useFetchFeatureFlags = () => {
  const client = useClient();

  const { data } = useQuery("featureflags", async () => {
    const res = await client.get<ApiFeatureFlags>("/featureflags");

    return res.data;
  });

  return data;
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagProvider"
    );
  }

  return context! as IFeatureFlagContext;
};
