import * as React from "react";
import { useHistory } from "react-router-dom";
import { CustomStandard } from "types";
import { Options } from "./RowOptions";

export const CustomStandardRow: React.FunctionComponent<CustomStandard> = (
  props
) => {
  const history = useHistory();
  const [showOptions, setShowOptions] = React.useState(false);

  return (
    <tr>
      <td className="px-6 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex items-center space-x-3">
          <div
            className="flex-shrink-0 w-2.5 h-2.5 rounded-full bg-cyan-600"
            aria-hidden="true"
          ></div>
          <a
            className="truncate cursor-pointer hover:text-gray-600"
            onClick={() => history.push(`/custom-standards/${props.crn}`)}
          >
            <span>{props.standard_id}</span>
          </a>
        </div>
      </td>
      <td className="px-6 py-3 text-sm text-gray-500">{props.title}</td>
      <td className="px-6 py-3 text-sm text-gray-500">{props.directive}</td>
      <td className="pr-6">
        <div className="relative flex justify-end items-center">
          <button
            type="button"
            className="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            id="standard-options-menu-0"
            aria-expanded="false"
            aria-haspopup="true"
            onClick={() => {
              setShowOptions(true);
            }}
          >
            <span className="sr-only">Open options</span>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>
          {showOptions && (
            <Options
              onClose={() => setShowOptions(false)}
              customStandard={props}
            />
          )}
        </div>
      </td>
    </tr>
  );
};
