import { useMutation, useQueryClient } from "react-query";
import { useClient } from "utils/client";
import { CustomStandard } from "types";
import { AxiosInstance } from "axios";

interface UpdateCustomStandardPayload {
  crn: string;
  title: string;
  directive: string;
  standard_id: string;
}

const updateCustomStandard = async (
  client: AxiosInstance,
  payload: UpdateCustomStandardPayload
) => {
  const { crn, ...data } = payload;
  const { data: response } = await client.put<CustomStandard>(
    `/custom-standards/${crn}`,
    { payload: data }
  );

  return response;
};

export const useUpdateCustomStandard = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UpdateCustomStandardPayload) =>
      await updateCustomStandard(client, payload),
    {
      onSuccess: (_, variables) => {
        queryClient.refetchQueries(["customStandard", variables.crn]);
        queryClient.refetchQueries(["customStandards"]);
      },
    }
  );
};
