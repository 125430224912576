import axios, { AxiosInstance } from "axios";
import { ControlContext } from "context/Controls";
import { uploadSignedS3 } from "pages/Project/hooks/useCreateProject";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { Descendant } from "slate";
import { useClient } from "utils/client";

export const useManual = (projectCrn: string) => {
  const client = useClient();

  const { data: manual, isLoading } = useQuery<any[]>(
    ["manuals", projectCrn],
    () => fetchManual(client, projectCrn)
  );

  return { manual, isLoading };
};

const fetchManual = async (client: AxiosInstance, crn: string) => {
  const { data: signedUrl } = await client.get(`/projects/${crn}/manual`);

  const { data } = await axios.get(signedUrl);

  return data;
};

const storeManual = async (
  client: AxiosInstance,
  crn: string,
  manual: Descendant[]
) => {
  if (manual) {
    const fileName = "slate.json";
    const jsonType = {
      type: "application/json",
    };
    const slateBlob = new Blob([JSON.stringify(manual)], jsonType);
    const slateFile = new File([slateBlob], fileName, jsonType);
    const uploadName = `/manual/${fileName}`;
    const prefix = `${crn}${uploadName}`;

    await uploadSignedS3(client, prefix, slateFile);
    const { data } = await client.put(`/projects/${crn}/manual`, {});

    return data;
  }
};

export const useManualSave = (crn: string) => {
  const client = useClient();
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { displayNotification } = useContext(ControlContext);

  const { mutate, isLoading } = useMutation(
    async (manual: any) => await storeManual(client, crn, manual),
    {
      onError: (e) =>
        displayNotification!(
          "Error storing manual: ",
          (e as Error).message,
          "error"
        ),
      onSuccess: () => {
        queryClient.refetchQueries(["manuals", crn]);

        displayNotification!(
          t("notification.saving.generic_success"),
          t("notification.saving.manual_success")
        );

        history.push(`/projects/${crn}`);
      },
    }
  );

  return { storeManual: mutate, isLoading };
};

export const useManualAutoSave = (crn: string) => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { displayNotification } = useContext(ControlContext);

  const { mutate, isLoading } = useMutation(
    async (manual: any) => await storeManual(client, crn, manual),
    {
      onError: (e) =>
        displayNotification!(
          "Error storing manual: ",
          (e as Error).message,
          "error"
        ),
      onSuccess: (_, manual) => {
        queryClient.setQueryData(["manuals", crn], manual);

        displayNotification!(
          t("notification.saving.generic_success"),
          t("notification.saving.manual_auto_success")
        );
      },
    }
  );

  return { autoSaveManual: mutate, isLoading };
};
