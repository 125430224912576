import { Project, Steps } from "types";
import { DocType } from "../types";
import { possibleSteps } from "components/Progress/Steps";

interface UsableDoc {
  type: DocType;
  label: string;
  visibility: string;
  completeFn: (p: Project) => boolean;
}

export const usableDocs: Record<DocType, UsableDoc> = {
  [DocType.manual]: {
    type: DocType.manual,
    label: "manual",
    visibility: "external",
    // completeFn: possibleSteps[Steps.manual].completeFn,
    completeFn: () => true,
  },
  [DocType.risk]: {
    type: DocType.risk,
    label: "risk",
    visibility: "internal",
    completeFn: (p) =>
      possibleSteps[Steps.risks].completeFn(p).complete &&
      possibleSteps[Steps.basedata].completeFn(p).complete &&
      possibleSteps[Steps.boundary].completeFn(p).complete,
  },
  [DocType.conformity]: {
    type: DocType.conformity,
    label: "conformity",
    visibility: "external",
    completeFn: (p) =>
      possibleSteps[Steps.basedata].completeFn(p).complete &&
      possibleSteps[Steps.standards].completeFn(p).complete &&
      possibleSteps[Steps.questionaire].completeFn(p).complete,
  },
  [DocType.installationConformity]: {
    type: DocType.installationConformity,
    label: "installationConformity",
    visibility: "external",
    completeFn: (p) =>
      possibleSteps[Steps.basedata].completeFn(p).complete &&
      possibleSteps[Steps.standards].completeFn(p).complete &&
      possibleSteps[Steps.questionaire].completeFn(p).complete,
  },
  [DocType.plate]: {
    type: DocType.plate,
    label: "plate",
    visibility: "external",
    completeFn: (p) => possibleSteps[Steps.basedata].completeFn(p).complete,
  },
  [DocType.exitConformity]: {
    type: DocType.exitConformity,
    label: "exit",
    visibility: "external",
    completeFn: (p) =>
      possibleSteps[Steps.basedata].completeFn(p).complete &&
      possibleSteps[Steps.questionaire].completeFn(p).complete,
  },
  [DocType.exitConformity_emv]: {
    type: DocType.exitConformity_emv,
    label: "exit_emv",
    visibility: "external",
    completeFn: (p) =>
      possibleSteps[Steps.basedata].completeFn(p).complete &&
      possibleSteps[Steps.questionaire].completeFn(p).complete,
  },
};
