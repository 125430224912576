import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { BookOpenIcon } from "@heroicons/react/outline";

import { useLogout } from "hooks/useLogout";
import { classNames } from "utils/classNames";
import { useUser } from "pages/Settings/hooks/useUser";
import { useAccount } from "pages/Settings/hooks/useAccount";
import { Feature, isDev, useFeatureFlags } from "context/FeatureFlag";
import { LanguageSelector } from "components/common/LanguageSelector";
import { Plan, PlanBadge } from "pages/Backoffice/TEST/PlanSelection";

const WIKI_URL_PROD = "https://docs.certain-cloud.de";
const WIKI_URL_DEV = "https://docs.dev.infra.certain-cloud.de";

const getWikiUrlForStage = (
  stage: string = process.env.NODE_ENV,
  lang: string
) => {
  let baseUrl;

  if (stage === "production") {
    baseUrl = WIKI_URL_PROD;
  } else {
    baseUrl = WIKI_URL_DEV;
  }

  const isDe = lang.match("de");
  return isDe ? baseUrl : `${baseUrl}/${lang.split("-")[0]}/docs/intro`;
};

export const DesktopSidebar = () => {
  const { user } = useUser();
  const logout = useLogout();
  const location = useLocation();
  const { account } = useAccount();
  const { t, i18n } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  const currentLanguage = i18n.language.split("-")[0];

  const imgRef = useRef<HTMLImageElement | null>(null);
  const wikiUrl = getWikiUrlForStage(process.env.NODE_ENV, i18n.language);

  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-white shadow">
        <div className="flex items-center flex-shrink-0 px-6">
          <a
            href="/dashboard"
            className="flex sm:justify-center lg:justify-start cursor-pointer"
          >
            <img
              src={`/Images/Logos/${currentLanguage}/logo.png`}
              alt=""
              className="h-12"
              ref={imgRef}
              //@ts-ignore
              onError={() => (imgRef.current.src = "Images/Logos/en/logo.png")}
            />
          </a>
        </div>
        <div className="h-0 flex-1 flex flex-col overflow-y-auto">
          <div className="px-3 mt-6 relative inline-block text-left">
            <div>
              <div
                className="group w-full bg-white rounded-md px-3.5 py-2 text-sm font-medium text-gray-700"
                id="options-menu"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span className="flex w-full justify-between items-center">
                  <span className="flex min-w-0 items-center justify-between space-x-3 overflow-hidden">
                    <span className="flex-1 min-w-0">
                      <span className="text-gray-900 text-sm font-medium truncate break-all">
                        {user?.name}
                        {!account?.subscription?.cancelled &&
                          account?.subscription?.plan && (
                            <PlanBadge plan={account.subscription.plan} />
                          )}
                        {account?.subscription?.cancelled && (
                          <PlanBadge plan={Plan.Cancelled} />
                        )}
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>

            <div
              className="hidden z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  View profile
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  Settings
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  Notifications
                </a>
              </div>
              <div className="py-1" role="none">
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  Get desktop app
                </a>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  Support
                </a>
              </div>
              <div className="py-1" role="none">
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
          <nav className="px-3 mt-6 h-full flex flex-col">
            <div className="space-y-1 flex-1">
              <Link
                data-test="nav-dashboard"
                to="/dashboard"
                className={classNames(
                  location.pathname === "/dashboard"
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                  "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="text-gray-500 mr-3 h-6 w-6 flex-shrink-0 mt-0.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  />
                </svg>
                <span className="flex-1 min-w-0 line-clamp-2">
                  {t("sidebar.nav.dashboard")}
                </span>
              </Link>
              <Link
                data-test="nav-manufacturers"
                to="/manufacturers"
                className={classNames(
                  location.pathname === "/manufacturers"
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                  "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="text-gray-500 mr-3 h-6 w-6 flex-shrink-0 mt-0.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                  />
                </svg>
                <span className="flex-1 min-w-0 line-clamp-2">
                  {t("sidebar.nav.manufacturers")}
                </span>
              </Link>

              <Link
                data-test="nav-custom-standards"
                to="/custom-standards"
                className={classNames(
                  location.pathname === "/custom-standards"
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                  "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="text-gray-500 mr-3 h-6 w-6 flex-shrink-0 mt-0.5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>
                <span className="flex-1 min-w-0 line-clamp-2">
                  {t("sidebar.nav.custom-standards")}
                </span>
              </Link>

              <Link
                data-test="nav-wiki"
                to={{
                  pathname: wikiUrl,
                }}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-700 hover:text-gray-900 hover:bg-gray-50 group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
              >
                <BookOpenIcon className="text-gray-500 mr-3 h-6 w-6 flex-shrink-0 mt-0.5" />
                <span className="flex-1 min-w-0 line-clamp-2">
                  CERTAIN Wiki
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-3 h-3 inline ml-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>
                </span>
              </Link>

              <Link
                data-test="nav-settings"
                to="/settings"
                className={classNames(
                  location.pathname === "/settings"
                    ? "bg-gray-200 text-gray-900"
                    : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                  "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-3 text-gray-500 flex-shrink-0 mt-0.5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  />
                </svg>
                <span className="flex-1 min-w-0 line-clamp-2">
                  {t("sidebar.nav.settings")}
                </span>
              </Link>
              {/* // PREVIEW */}
              {isFeatureEnabled(Feature.PowerSearch) && (
                <div className="pt-12">
                  <Link
                    to="/power-search"
                    className={classNames(
                      location.pathname === "/power-search"
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                      "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center mb-8"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6 mr-3 text-cyan-600 flex-shrink-0 mt-0.5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                      />
                    </svg>

                    <span className="flex-1 min-w-0 line-clamp-2">
                      {t("sidebar.nav.powersearch")}
                    </span>
                  </Link>
                </div>
              )}
              {/* PREVIEW END */}

              {user?.is_admin && (
                <>
                  <div className="pt-12">
                    <Link
                      to="/_backoffice/_featureflags"
                      className={classNames(
                        location.pathname === "/_backoffice/_featureflags"
                          ? "bg-gray-200 text-gray-900"
                          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                        "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="h-6 w-6 mr-3 text-red-500 flex-shrink-0 mt-0.5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.484 2.17a.75.75 0 0 1 1.032 0 11.209 11.209 0 0 0 7.877 3.08.75.75 0 0 1 .722.515 12.74 12.74 0 0 1 .635 3.985c0 5.942-4.064 10.933-9.563 12.348a.749.749 0 0 1-.374 0C6.314 20.683 2.25 15.692 2.25 9.75c0-1.39.223-2.73.635-3.985a.75.75 0 0 1 .722-.516l.143.001c2.996 0 5.718-1.17 7.734-3.08ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75ZM12 15a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75H12Z"
                          clipRule="evenodd"
                        />
                      </svg>

                      <span className="flex-1 min-w-0 line-clamp-2">
                        Feature Flag Configuration
                      </span>
                    </Link>
                  </div>
                </>
              )}

              {user?.is_admin && isDev && (
                <>
                  <div>
                    <Link
                      to="/_backoffice/_questionaire"
                      className={classNames(
                        location.pathname === "/_backoffice/_questionaire"
                          ? "bg-gray-200 text-gray-900"
                          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                        "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3 text-gray-500 flex-shrink-0 mt-0.5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M12 14l9-5-9-5-9 5 9 5z" />
                        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                        />
                      </svg>
                      <span className="flex-1 min-w-0 line-clamp-2">
                        {t("sidebar.nav.backoffice-questionaire")}
                      </span>
                    </Link>

                    <Link
                      to="/_backoffice/_manual"
                      className={classNames(
                        location.pathname === "/_backoffice/_manual"
                          ? "bg-gray-200 text-gray-900"
                          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                        "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3 text-gray-500 flex-shrink-0 mt-0.5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                      <span className="flex-1 min-w-0 line-clamp-2">
                        {t("sidebar.nav.operating-instructions")}
                      </span>
                    </Link>

                    <Link
                      to="/_backoffice/_docs"
                      className={classNames(
                        location.pathname === "/_backoffice/_docs"
                          ? "bg-gray-200 text-gray-900"
                          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                        "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3 text-gray-500 flex-shrink-0 mt-0.5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                      <span className="flex-1 min-w-0 line-clamp-2">
                        Backoffice: Dokumentation
                      </span>
                    </Link>

                    <Link
                      to="/_backoffice/_risks"
                      className={classNames(
                        location.pathname === "/_backoffice/_risks"
                          ? "bg-gray-200 text-gray-900"
                          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                        "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mr-3 text-gray-500 flex-shrink-0 mt-0.5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        />
                      </svg>
                      <span className="flex-1 min-w-0 line-clamp-2">
                        Backoffice: Gefährdungsvorlagen
                      </span>
                    </Link>

                    <Link
                      to="/_backoffice/_test/checkout"
                      className={classNames(
                        location.pathname === "/_backoffice/_risks"
                          ? "bg-gray-200 text-gray-900"
                          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                        "group flex items-start px-2 py-2 text-sm font-medium rounded-md items-center"
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 mr-3 text-gray-500 flex-shrink-0 mt-0.5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <span className="flex-1 min-w-0 line-clamp-2">
                        TEST: Checkout/Payment
                      </span>
                    </Link>
                  </div>
                </>
              )}
            </div>

            <div className="mb-8 h-fit">
              <LanguageSelector />

              <div
                className="mt-1 space-y-1"
                role="group"
                aria-labelledby="teams-headline"
              >
                <span
                  data-test="nav-logout"
                  onClick={logout}
                  className="group flex items-start px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50 text-center cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-3 flex-shrink-0 mt-0.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                  <span className="flex-1 min-w-0 line-clamp-2">
                    {t("sidebar.nav.logout")}
                  </span>
                </span>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
