import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Estimated total time in seconds
const ESTIMATED_TOTAL_TIME_SECONDS = 40;

export const MultiStepLoader = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [elapsedTime, setElapsedTime] = useState<number>(0);

  const [steps, setSteps] = useState<string[]>([]);

  useEffect(() => {
    if (!steps.length) {
      setSteps([
        t("powersearch.searchPage.loading.loadingDatabases"),
        t("powersearch.searchPage.loading.searchingStandards"),
        t("powersearch.searchPage.loading.filterResults"),
        t("powersearch.searchPage.loading.linkStandards"),
        t("powersearch.searchPage.loading.instructAi"),
        t("powersearch.searchPage.loading.summarizingQuery"),
      ]);
    }
  }, [steps, t]);

  // Effect for step progression
  useEffect(() => {
    // Simulate async tasks for each step.
    if (currentStep < steps.length) {
      const timer = setTimeout(() => {
        // Here you would trigger your async task for each step.
        // After it completes, you move to the next step.
        setCurrentStep((prev) => prev + 1);
      }, (ESTIMATED_TOTAL_TIME_SECONDS * 1000) / steps.length); // simulate delay for each step

      return () => clearTimeout(timer);
    }
  }, [currentStep, steps]);

  // Effect for timer
  useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Format time as mm:ss
  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  // Calculate progress percentage
  const progressPercentage = Math.min(
    Math.round((elapsedTime / ESTIMATED_TOTAL_TIME_SECONDS) * 100),
    100
  );

  return (
    <div className="mt-8 p-6 bg-white/80 backdrop-blur-sm border border-gray-200 rounded-xl shadow-sm max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-800 flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-cyan-500 animate-spin"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
          {t("powersearch.searchPage.loading.processing")}
        </h2>
        <div className="flex flex-col items-end">
          <div className="text-sm font-medium text-gray-500 bg-gray-100 px-3 py-1 rounded-full flex items-center gap-1 mb-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            {formatTime(elapsedTime)} /{" "}
            {formatTime(ESTIMATED_TOTAL_TIME_SECONDS)}
          </div>
          <div className="w-32 h-1.5 bg-gray-200 rounded-full overflow-hidden">
            <div
              className="h-full bg-cyan-500 rounded-full transition-all duration-300 ease-out"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
        </div>
      </div>
      <ul className="space-y-3">
        {steps.map((step, index) => {
          // Define the state of this step
          const isCompleted = index < currentStep;
          const isActive = index === currentStep;
          const isPending = index > currentStep;

          return (
            <li
              key={index}
              className={`flex items-center gap-3 p-2 rounded-lg transition-all duration-300 ${
                isActive
                  ? "bg-cyan-50 text-cyan-700 animate-pulse"
                  : isCompleted
                  ? "text-gray-700"
                  : "text-gray-400 opacity-60"
              }`}
            >
              <div
                className={`flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full ${
                  isCompleted
                    ? "bg-cyan-100 text-cyan-600"
                    : isActive
                    ? "bg-cyan-500 text-white"
                    : "bg-gray-200 text-gray-400"
                }`}
              >
                {isCompleted ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : isActive ? (
                  <div className="h-2 w-2 bg-white rounded-full"></div>
                ) : (
                  <div className="h-2 w-2 bg-gray-400 rounded-full"></div>
                )}
              </div>
              <span
                className={`transition-opacity duration-300 ${
                  isActive
                    ? "opacity-100 font-medium"
                    : isPending
                    ? "opacity-60"
                    : ""
                }`}
              >
                {step}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
