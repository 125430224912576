import { useTranslation } from "react-i18next";

import SearchPage from "./Search";
import { BasePage } from "components/BasePage/BasePage";

export const PowerSearch = () => {
  const { t } = useTranslation();

  return (
    <BasePage
      breadcrumbItems={[
        { label: "sidebar.nav.powersearch", location: "/power-search" },
        {
          label: "powersearch.title",
          location: "/power-search",
        },
      ]}
    >
      <div className="">
        <div>
          <SearchPage />
        </div>
      </div>
    </BasePage>
  );
};
