import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ExampleQueriesProps {
  onSelectQuery: (query: string) => void;
}

const ExampleQueries = ({ onSelectQuery }: ExampleQueriesProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const exampleQueries = [
    t("powersearch.exampleQueries.query.safety_requirement_robot"),
    t("powersearch.exampleQueries.query.en_iso_13849"),
    t("powersearch.exampleQueries.query.machine_guarding"),
    t("powersearch.exampleQueries.query.emergency_stop"),
  ];

  return (
    <div className="mt-2 mb-6 max-w-4xl mx-auto">
      <div className="flex items-center justify-center">
        <button
          onClick={() => setExpanded(!expanded)}
          className="text-sm text-cyan-600 hover:text-cyan-800 flex items-center focus:outline-none"
        >
          <span>{t("powersearch.exampleQueries.toggle")}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className={`w-4 h-4 ml-1 transition-transform ${
              expanded ? "rotate-180" : ""
            }`}
          >
            <path
              fillRule="evenodd"
              d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {expanded && (
        <div className="mt-3 bg-white rounded-xl shadow-sm border border-gray-200 p-4 transition-all">
          <h3 className="text-sm font-medium text-gray-700 mb-3">
            {t("powersearch.exampleQueries.title")}
          </h3>
          <div className="flex flex-wrap gap-2">
            {exampleQueries.map((query, index) => (
              <button
                key={index}
                onClick={() => {
                  onSelectQuery(query);
                  setExpanded(false);
                }}
                className="px-3 py-1.5 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-full text-sm transition-colors"
              >
                {query}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExampleQueries;
