import React from "react";
import axios, { AxiosError, AxiosInstance } from "axios";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { boundarySections } from "@slootsantos/certain-forms/dist/forms/ce";

import Form from "forms/Form";
import { Project } from "types";
import { useClient } from "utils/client";
import { TrackingContext } from "context/Tracking";
import { FeedbackContext } from "context/Feedback";
import { BasePage } from "components/BasePage/BasePage";
import {
  ProjectPayload,
  uploadSignedS3,
} from "./Project/hooks/useCreateProject";
import { Feature, useFeatureFlags } from "context/FeatureFlag";
import { SpecialUploadType } from "components/Documents/types";
import { FeedbackLocation } from "components/Feedback/FeedbackPopup";
import { FormFieldType } from "@slootsantos/certain-forms/dist/types";
import { useProjectDetails } from "./Details/hooks/useProjectDetails";

interface EditRouteParams {
  projectId: string;
}

const updateProject = async (
  client: AxiosInstance,
  projectId: string,
  payload: ProjectPayload,
  additional_boundaries: { description: string; value: string }[]
) => {
  payload.project.boundary.additional_boundaries = additional_boundaries.filter(
    (d) => !!d.description && !!d.value
  );

  await client.put(`/projects/${projectId}`, {
    payload: payload.project,
  });
};

const useDerivedData = (projectId: string) => {
  const queryClient = useQueryClient();

  const { project: projectData, fetchInProgress: isProjectLoading } =
    useProjectDetails(projectId);

  // Find detailed description document
  const detailedDescDoc = projectData?.documents?.find(
    (doc) => doc.type === SpecialUploadType.detailedDescription
  );

  // Fetch detailed description content if document exists
  const { data: detailedDescContent, isLoading: isDescLoading } = useQuery(
    ["detailedDescription", detailedDescDoc?.location],
    async () => {
      if (!detailedDescDoc?.location) return null;

      const { data } = await axios.get(detailedDescDoc.location);
      return data;
    },
    {
      enabled: !!detailedDescDoc?.location,
      retry: (_, err) => {
        // The fetch might in some cases return a 403 (which in S3 lingo is likely to be a expired request)
        // In that case we stop the retry and refetch the project and detailed description to get a newly signed URL
        if ((err as AxiosError).response?.status === 403) {
          queryClient.refetchQueries(["projects", projectId]);
          queryClient.refetchQueries([
            "detailedDescription",
            detailedDescDoc?.location,
          ]);

          return false;
        }

        return true;
      },
    }
  );

  // Combine project data with uploads and detailed description
  const data = React.useMemo(() => {
    if (!projectData) return projectData;

    const _uploads = projectData.documents?.reduce((agg, doc) => {
      agg[doc.type] = { image: new File([], doc.name.split("/upload/")[1]) };
      return agg;
    }, {} as Record<string, { image: File }>);

    return {
      ...projectData,
      _uploads,
      boundary: {
        ...projectData.boundary,
        detailed_description: detailedDescContent,
      },
    };
  }, [projectData, detailedDescContent]);

  const isLoading = isProjectLoading || isDescLoading;

  return { data, isLoading };
};

export const EditBoundaries = () => {
  const { t } = useTranslation();
  const client = useClient();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { projectId } = useParams<EditRouteParams>();
  const { displayFeedback } = React.useContext(FeedbackContext);
  const { isFeatureEnabled } = useFeatureFlags();

  const { track } = React.useContext(TrackingContext);

  const { data, isLoading } = useDerivedData(projectId);

  const [formData, setFormData] = React.useState({
    sections: boundarySections,
  });
  const [defaultData, setDefaultData] = React.useState(data);
  const [blocking, setBlocking] = React.useState(true);

  React.useEffect(() => {
    if (
      boundarySections.find((s) => s.label === "form.boundaryDetailed.label")
    ) {
      return;
    }

    boundarySections.push({
      label: "form.boundaryDetailed.label",
      name: "boundary",
      subline: "form.boundaryDetailed.subline",
      info: "form.boundaryDetailed.info",
      fields: [
        {
          name: "detailed_description",
          label: "form.boundaryDetailed.description",
          type: FormFieldType.editor,
        },
      ],
    });
  }, [isFeatureEnabled]);

  React.useEffect(() => {
    if (!formData?.sections?.length || !data) return;

    const copy = structuredClone(formData);
    const copyData = structuredClone(data);
    const idx = copy.sections.findIndex(
      (s) => s.name === "additional_boundaries"
    );
    copy.sections[idx].count = data.boundary?.additional_boundaries?.length;

    // @ts-ignore
    copyData["additional_boundaries"] = data.boundary?.additional_boundaries;

    if (
      copyData.boundary &&
      (!copyData!.boundary.expected_misuse ||
        copyData.boundary.expected_misuse === "")
    ) {
      copyData!.boundary!.expected_misuse = t(
        "form.boundary.default_expected_misuse"
      );
    }

    setFormData(copy);
    setDefaultData(copyData);
    setBlocking(
      isLoading ||
        !Boolean(data?.projectdata) ||
        !Boolean(copy.sections?.length)
    );
  }, [data, isLoading, t]);

  const updateBoundaries = async (values: any) => {
    const { _uploads, additional_boundaries, ...project } = values;
    const { detailed_description } = { ...project.boundary };
    delete project.boundary.detailed_description;

    // First update project
    // then upload image etc
    // otherwise you cause race conditions
    await updateProject(
      client,
      projectId,
      { project, uploads: _uploads },
      additional_boundaries
    );

    if (detailed_description) {
      const fileName = "detailed_description.slate.json";
      const jsonType = {
        type: "application/json",
      };
      const slateBlob = new Blob(
        [JSON.stringify(detailed_description)],
        jsonType
      );
      const slateFile = new File([slateBlob], fileName, jsonType);
      const uploadName = `/upload/${fileName}`;
      const prefix = `${projectId}${uploadName}`;

      await uploadSignedS3(client, prefix, slateFile);
      await client.post(`/projects/${projectId}/documents`, {
        type: SpecialUploadType.detailedDescription,
        name: uploadName,
      });
    }

    queryClient.invalidateQueries(["projects", projectId]);

    track("update boundaries");
    displayFeedback!(FeedbackLocation.Boundary);
    history.push("/projects/" + projectId + "?saved=true");
  };

  return (
    <BasePage
      loading={blocking}
      breadcrumbItems={[
        { label: "projects", location: "/dashboard" },
        {
          label: data?.projectdata?.product_name! || projectId,
          location: `/projects/${projectId}`,
        },
        {
          label: "form.boundary.label",
          location: `/projects/${projectId}/edit/boundaries`,
        },
      ]}
    >
      <Form
        saveText={t("form.boundary.save")}
        formData={formData}
        confirmationSubline={"form.boundary.delete_confirmation_subline"}
        defaultValues={defaultData}
        onSubmit={updateBoundaries}
      />
    </BasePage>
  );
};
