import React from "react";
import rehypeRaw from "rehype-raw";
import { useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

import { standardsWithContent } from "./statics/standards";
import { SearchResult, DocumentType } from "./types/search";
import {
  useAllTranslatedStandards,
  useTranslatedStandards,
} from "hooks/useStandards";

interface SearchResultsProps {
  results: SearchResult[];
  totalResults: number;
  summary?: string;
  standards?: string[];
  executionTimeMs?: number;
  isLoading: boolean;
  query: string;
}

const parseStandardsInText = (text: string) => {
  if (!text) return null;

  // Split the text by <standard> tags
  const parts = text.split(/<standard>|<\/standard>/);

  return parts
    .map((part, index) => {
      // Even indices are regular text, odd indices are standards
      if (index % 2 === 0) {
        return null;
      } else {
        // This is a standard, highlight it
        return part;
      }
    })
    .filter(Boolean);
};

const SearchResults = ({
  results,
  summary,
  standards: resultStandards,
  executionTimeMs,
  isLoading,
  query,
}: SearchResultsProps) => {
  const { t } = useTranslation();
  const parsedStandardsFromResult = parseStandardsInText(summary || "");
  const standards = [
    ...(resultStandards || []),
    ...(parsedStandardsFromResult || []),
  ];

  // Helper function to translate document type
  const getDocumentTypeLabel = (type: DocumentType) =>
    t(`documentTypes.${type}`, { defaultValue: type });

  // Fetch standards data
  const { translations } = useAllTranslatedStandards();

  const getStandard = (standardId: string) => {
    const standard = translations?.find((s) => s.name.match(standardId));

    return standard;
  };

  // State for tooltip
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode | null>(
    null
  );
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipTimeoutRef = useRef<number | null>(null);

  // Helper function to show tooltip
  const showTooltip = (standardName: string, event: React.MouseEvent) => {
    const standard = getStandard(standardName.split(":")[0]);
    const standardContentKey =
      Object.keys(standardsWithContent).find((s) => s.match(standardName)) ||
      "default";
    // @ts-expect-error - standardContentKey is a valid key
    const standardContent = standardsWithContent[standardContentKey];

    if (standard) {
      // Format the tooltip content with additional information
      const formattedContent = (
        <>
          <div className="font-semibold text-cyan-700 mb-2">
            {standard.name} {standard.latest && `(${standard.latest})`}
          </div>
          {standard.type && (
            <div className="mb-1">
              <span className="font-medium">Typ:</span> {standard.type}
            </div>
          )}
          {standard.directive && (
            <div className="mb-1">
              <span className="font-medium">Richtlinie:</span>{" "}
              {t(`directives.enum.${standard.directive}`)}
            </div>
          )}
          {standard.retracted && (
            <div className="mb-2 text-red-600 font-medium">Zurückgezogen</div>
          )}
          <div className="mt-2 pt-2 border-t border-gray-200">
            <span className="font-medium">Titel:</span>{" "}
            <p>{standard.content}</p>
          </div>
          {standardContent && standardContent.rewordedContent && (
            <div className="mt-2 pt-2 border-t border-gray-200">
              <p>{standardContent.rewordedContent}</p>
            </div>
          )}
        </>
      );

      setTooltipContent(formattedContent);
      setTooltipPosition({
        x: event.clientX,
        y: event.clientY,
      });
      setIsTooltipVisible(true);

      // Clear any existing timeout
      if (tooltipTimeoutRef.current !== null) {
        window.clearTimeout(tooltipTimeoutRef.current);
      }
    }
  };

  // Helper function to hide tooltip
  const hideTooltip = () => {
    // Add a small delay before hiding to allow moving to the tooltip
    tooltipTimeoutRef.current = window.setTimeout(() => {
      setIsTooltipVisible(false);
    }, 300);
  };

  // Custom component to render markdown with standards highlighting
  const MarkdownWithStandards = ({ content }: { content: string }) => {
    if (!content) return null;

    return (
      <div>
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          components={{
            // @ts-expect-error - custom component
            standard: ({ children }) => {
              return (
                <span
                  className="bg-cyan-50 text-cyan-700 px-1 py-0.5 rounded border border-cyan-200 font-medium cursor-pointer hover:bg-cyan-100 transition-colors"
                  onMouseEnter={(e) => showTooltip(children, e)}
                  onMouseLeave={hideTooltip}
                >
                  {children}
                </span>
              );
            },
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
    );
  };

  const standardsForResult: SearchResult[] = standards
    .map((standard) => {
      const { content } = getStandard(standard!) || { content: "" };

      if (!content) return null;

      return {
        id: `standard-{standard}`,
        title: standard!,
        content_snippet: content,
        document_type: DocumentType.STANDARD,
        relevance_score: 1,
        metadata: {},
      };
    })
    .filter(Boolean) as SearchResult[];

  // Group results by document type
  const groupedResults = [...results, ...standardsForResult].reduce<
    Record<string, SearchResult[]>
  >((groups, result) => {
    const type = result.document_type;
    if (!groups[type]) {
      groups[type] = [];
    }
    groups[type].push(result);
    return groups;
  }, {});

  // Sort document types to ensure consistent order
  const documentTypes = Object.keys(groupedResults).sort((a, b) => {
    // Custom sort order: STANDARD, DIRECTIVE, PDF, HTML, TEXT
    const order: Record<string, number> = {
      [DocumentType.STANDARD]: 1,
      [DocumentType.DIRECTIVE]: 2,
      [DocumentType.PDF]: 3,
      [DocumentType.HTML]: 4,
      [DocumentType.TEXT]: 5,
    };
    return (order[a] || 99) - (order[b] || 99);
  });

  // Track which groups are expanded
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>(
    documentTypes.reduce((acc, type) => ({ ...acc, [type]: true }), {})
  );

  // Toggle group expansion
  const toggleGroup = (type: string) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  // Render a single result item
  const renderResultItem = (result: SearchResult) => (
    <div
      key={result.id}
      className="card p-6 hover:shadow-md transition-all duration-200 border-l-4 border-l-cyan-400 mb-4"
    >
      <div className="flex items-start justify-between gap-4">
        <h3 className="text-lg font-semibold text-gray-800">{result.title}</h3>
      </div>

      <p className="mt-3 text-gray-600">{result.content_snippet}</p>

      {/* Metadata */}
      <div className="mt-4 flex flex-wrap gap-2">
        {result.metadata.standard_id && (
          <span className="text-xs bg-gray-50 text-gray-700 px-3 py-1 rounded-full border border-gray-100">
            {t("powersearch.searchResults.standard")}:{" "}
            {result.metadata.standard_id}
            {result.metadata.standard_version &&
              ` (${result.metadata.standard_version})`}
          </span>
        )}
        {result.metadata.directive_id && (
          <span className="text-xs bg-gray-50 text-gray-700 px-3 py-1 rounded-full border border-gray-100">
            {t("powersearch.searchResults.directive")}:{" "}
            {result.metadata.directive_id}
          </span>
        )}
        {result.metadata.source && (
          <span className="text-xs bg-gray-50 text-gray-700 px-3 py-1 rounded-full border border-gray-100">
            {t("powersearch.searchResults.source")}: {result.metadata.source}
          </span>
        )}
      </div>

      {/* Relevance score */}
      <div className="mt-4 flex items-center">
        <div className="flex-grow h-1.5 bg-gray-100 rounded-full overflow-hidden">
          <div
            className="h-full bg-gradient-to-r from-blue-400 to-cyan-500 rounded-full"
            style={{ width: `${result.relevance_score * 100}%` }}
          ></div>
        </div>
        <span className="text-xs font-medium text-gray-500 ml-3 min-w-[4rem] text-right">
          {(result.relevance_score * 100).toFixed(0)}%{" "}
          {t("powersearch.searchResults.relevant")}
        </span>
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-pulse flex flex-col space-y-6 w-full">
          <div className="h-4 bg-gray-200 rounded-full w-3/4"></div>
          <div className="h-4 bg-gray-200 rounded-full w-1/2"></div>
          <div className="h-4 bg-gray-200 rounded-full w-5/6"></div>
          <div className="h-4 bg-gray-200 rounded-full w-2/3"></div>
          <div className="h-32 bg-gray-100 rounded-xl w-full"></div>
          <div className="h-32 bg-gray-100 rounded-xl w-full"></div>
        </div>
      </div>
    );
  }

  if (query && results.length === 0 && !summary) {
    return (
      <div className="text-center py-16">
        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-8 h-8 text-gray-400"
          >
            <path
              fillRule="evenodd"
              d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h3 className="text-xl font-semibold text-gray-700">
          {t("powersearch.searchResults.noResults")}
        </h3>
        <p className="text-gray-500 mt-2 max-w-md mx-auto">
          {t("powersearch.searchResults.noResultsHint")}
        </p>
      </div>
    );
  }

  if (!query) {
    return (
      <div className="text-center py-16">
        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-cyan-800 via-cyan-600 to-cyan-300 mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-8 h-8 text-white"
          >
            <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
            <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
          </svg>
        </div>
        <h3 className="text-xl font-semibold text-gray-700">
          {t("powersearch.searchResults.startHint")}
        </h3>
        <p className="text-gray-500 mt-2 max-w-md mx-auto">
          {t("powersearch.searchResults.startSubHint")}
        </p>
      </div>
    );
  }

  return (
    <div className="mt-12 relative">
      {/* Tooltip */}
      {isTooltipVisible && tooltipContent && (
        <div
          className="fixed z-50 bg-white p-4 rounded-lg shadow-lg border border-gray-200 max-w-md"
          style={{
            left: `${Math.min(
              tooltipPosition.x + 10,
              window.innerWidth - 350
            )}px`,
            top: `${Math.min(
              tooltipPosition.y + 10,
              window.innerHeight - 200
            )}px`,
          }}
          onMouseEnter={() => {
            // Clear the timeout when mouse enters the tooltip
            if (tooltipTimeoutRef.current !== null) {
              window.clearTimeout(tooltipTimeoutRef.current);
            }
          }}
          onMouseLeave={() => {
            setIsTooltipVisible(false);
          }}
        >
          <div className="text-sm text-gray-700 max-h-60 overflow-y-auto">
            {tooltipContent}
          </div>
        </div>
      )}
      {/* Summary section */}
      {summary && (
        <div className="bg-gradient-to-r from-blue-50 to-cyan-50 border border-cyan-100 rounded-xl p-6 mb-8 shadow-sm">
          <div className="text-gray-700 prose prose-sm max-w-none">
            <MarkdownWithStandards content={summary} />
          </div>

          <div className="mt-4 pt-4 prose prose-sm max-w-none">
            <h2 className="font-semibold text-gray-800 mb-2">
              {t("powersearch.searchResults.disclaimer.title")}:
            </h2>
            <p>{t("powersearch.searchResults.disclaimer.content")}</p>
          </div>

          {/* Standards section */}
          {standards && standards.length > 0 && (
            <div className="mt-4 pt-4 border-t border-cyan-100">
              <h4 className="text-md font-semibold text-gray-800 mb-2">
                {t("powersearch.searchResults.mentionedStandards")}:
              </h4>
              <div className="flex flex-wrap gap-2">
                {standards.map((standard, index) => (
                  <span
                    key={index}
                    className="text-sm bg-white text-cyan-700 px-3 py-1 rounded-full border border-cyan-200 shadow-sm cursor-pointer hover:bg-cyan-50 transition-colors"
                    onMouseEnter={(e) => showTooltip(standard!, e)}
                    onMouseLeave={hideTooltip}
                  >
                    {standard}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Results metadata */}
      {/* <div className="flex justify-between items-center mb-6">
        <p className="text-sm text-gray-500 bg-gray-50 px-3 py-1 rounded-full border border-gray-100">
          {standards.length} {t("powersearch.searchResults.results")} (
          {executionTimeMs ? (executionTimeMs / 1000).toFixed(2) : "0"}{" "}
          {t("powersearch.searchResults.seconds")})
        </p>
      </div> */}

      {/* Grouped results */}
      {/* <div className="space-y-6">
        {documentTypes.map((type) => {
          const typeResults = groupedResults[type];
          const isExpanded = expandedGroups[type];

          return (
            <div
              key={type}
              className="border border-gray-200 rounded-xl overflow-hidden shadow-sm"
            >
              <div
                className="flex items-center justify-between p-4 cursor-pointer bg-gray-50 border-b border-gray-200"
                onClick={() => toggleGroup(type)}
              >
                <div className="flex items-center">
                  <h3 className="font-semibold text-gray-800">
                    {getDocumentTypeLabel(type as DocumentType)}
                  </h3>
                  <span className="ml-2 text-sm text-gray-600">
                    ({typeResults.length})
                  </span>
                </div>
                <div className="flex items-center">
                  <div
                    className="transform transition-transform duration-200"
                    style={{
                      transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {isExpanded && (
                <div className="p-4 bg-white">
                  {typeResults.map(renderResultItem)}
                </div>
              )}
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default SearchResults;
