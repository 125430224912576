export enum DocumentType {
  DIRECTIVE = "directive",
  STANDARD = "standard",
  PDF = "pdf",
  HTML = "html",
  TEXT = "text",
}

export interface SearchFilter {
  directive_id?: string;
  standard_id?: string;
  document_type?: DocumentType[]; // Keep for backward compatibility, but use document_types in SearchRequest
}

export interface SearchRequest {
  query: string;
  filters?: SearchFilter;
  document_types?: DocumentType[];
  limit?: number;
  lang: string;
}

export interface SearchResultMetadata {
  [key: string]: string | number | boolean | undefined;
  standard_id?: string;
  standard_version?: string;
  directive_id?: string;
  directive_year?: number;
  source?: string;
  url?: string;
}

export interface SearchResult {
  id: string;
  title: string;
  document_type: DocumentType;
  content_snippet: string;
  relevance_score: number;
  metadata: SearchResultMetadata;
}

export interface StandardContent {
  name: string;
  content: string;
  retracted?: boolean;
  type?: string;
  directive?: string;
  lastRecorded?: string;
  versions?: string[];
  latest?: string;
}

export interface SearchResponse {
  results: SearchResult[];
  total_results: number;
  query: string;
  filters_applied: SearchFilter;
  execution_time_ms: number;
  summary?: string;
  standards?: string[];
}
