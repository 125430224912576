import * as React from "react";
import { useHistory } from "react-router-dom";
import { CustomStandard } from "types";
import { useTranslation } from "react-i18next";
import { useDeleteCustomStandard } from "./hooks/useDeleteCustomStandard";
import { useConfirmation } from "context/Confirmation";

interface OptionsProps {
  onClose: () => void;
  customStandard: CustomStandard;
}

export const Options: React.FunctionComponent<OptionsProps> = ({
  onClose,
  customStandard,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { openConfirmation } = useConfirmation();
  const { mutate: deleteCustomStandard } = useDeleteCustomStandard();

  const handleDelete = () => {
    openConfirmation(
      () => {
        deleteCustomStandard({ crn: customStandard.crn });
      },
      {
        label: t("customStandard.delete"),
        subline: t("customStandard.delete_subline"),
      }
    );
  };

  return (
    <div
      className="mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="project-options-menu-0"
    >
      <div className="py-1" role="none">
        <button
          onClick={() => {
            history.push(`/custom-standards/${customStandard.crn}`);
            onClose();
          }}
          className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 w-full text-left"
          role="menuitem"
        >
          {t("dashboard.table.edit")}
        </button>
      </div>
      <div className="py-1" role="none">
        <button
          onClick={() => {
            handleDelete();
            onClose();
          }}
          className="text-red-700 block px-4 py-2 text-sm hover:bg-gray-100 w-full text-left"
          role="menuitem"
        >
          {t("dashboard.table.delete")}
        </button>
      </div>
    </div>
  );
};
