import i18next from "i18next";
import { AxiosInstance } from "axios";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";

import { useClient } from "utils/client";
import { SearchRequest, SearchResponse, SearchFilter } from "../types/search";

const searchPowerSearch = async (
  client: AxiosInstance,
  request: SearchRequest
) => {
  const { data } = await client.post<SearchResponse>("/power-search", request);

  return data;
};

export const useSearch = (initialQuery = "") => {
  const client = useClient();
  const language = i18next.language;
  const [query, setQuery] = useState<string>(initialQuery);
  const [filters, setFilters] = useState<SearchFilter>({});
  const [limit, setLimit] = useState<number>(15);
  const [shouldSearch, setShouldSearch] = useState<boolean>(false);

  // Create the search request
  const searchRequest: SearchRequest = {
    query,
    filters: Object.keys(filters).length > 0 ? { ...filters } : undefined,
    document_types: filters.document_type,
    limit,
    lang: language,
  };

  const { data, isLoading, isError, error } = useQuery<SearchResponse, Error>({
    queryKey: ["search", searchRequest],
    queryFn: () => searchPowerSearch(client, searchRequest),
    enabled: shouldSearch && query.trim().length > 0, // Only run the query if explicitly triggered and there's a search term
    // staleTime: 5 * 60 * 1000, // 5 minutes
  });

  // Reset shouldSearch after the query is completed
  useEffect(() => {
    if (!isLoading) {
      setShouldSearch(false);
    }
  }, [isLoading]);

  // Function to perform a search
  const performSearch = (newQuery?: string) => {
    if (newQuery !== undefined) {
      setQuery(newQuery);
    }
    setShouldSearch(true);
  };

  // Function to update filters
  const updateFilters = (newFilters: SearchFilter) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  // Function to clear filters
  const clearFilters = () => {
    setFilters({});
  };

  // Function to update limit
  const updateLimit = (newLimit: number) => {
    setLimit(newLimit);
  };

  return {
    query,
    setQuery,
    filters,
    updateFilters,
    clearFilters,
    limit,
    updateLimit,
    performSearch,
    results: data?.results || [],
    totalResults: data?.total_results || 0,
    summary: data?.summary,
    standards: data?.standards,
    executionTimeMs: data?.execution_time_ms,
    isLoading,
    isError,
    error,
  };
};
