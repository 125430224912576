import { Descendant } from "slate";
import { Slate, Editable } from "slate-react";
import { useTranslation } from "react-i18next";
import { useEditorConfig } from "./hooks/useEditorConfig";

import { useEditorRenderers } from "./hooks/useEditorRenderers";
import { useKeyboardHandlers } from "./hooks/useKeyboardHandlers";
import {
  CustomToolbar,
  ToolbarGroup,
} from "components/ManualBuilder/components/CustomToolbar";

const initialValue: Descendant[] = [
  { type: "paragraph", children: [{ text: "" }] },
];

export const SlateEditor = (props: {
  onBlur: (data: Descendant[]) => void;
  label: string;
  required?: boolean;
  defaultValue: Descendant[];
}) => {
  const { t } = useTranslation();
  const editor = useEditorConfig(false);
  const onKeyDown = useKeyboardHandlers(editor);
  const { renderElement, renderLeaf } = useEditorRenderers();

  return (
    <div className="col-span-6">
      <div className="flex justify-between pb-2">
        <label className="block text-sm font-medium text-gray-700">
          {t(props.label)}
        </label>
        {!props.required && (
          <span id="phone-optional" className="text-sm text-gray-400">
            optional
          </span>
        )}
      </div>
      <div className="bg-white rounded-md shadow relative overflow-hidden resize-y min-h-[300px] max-h-[90vh]">
        <Slate
          editor={editor}
          onChange={(content) =>
            // @ts-ignore
            props.onBlur?.({ target: { value: content } })
          }
          initialValue={props.defaultValue || initialValue}
        >
          <div className="sticky top-0 z-10 bg-white border-b">
            <CustomToolbar
              groups={[
                ToolbarGroup.Formatting,
                ToolbarGroup.Lists,
                ToolbarGroup.Insertions,
              ]}
            />
          </div>
          <div className="p-4 overflow-y-auto h-full">
            <Editable
              onKeyDown={onKeyDown}
              renderLeaf={renderLeaf}
              renderElement={renderElement}
              placeholder="Start typing..."
              className="min-h-[200px] focus:outline-none"
            />
          </div>
        </Slate>
      </div>
    </div>
  );
};
