import { AxiosInstance } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { CustomStandard } from "types";
import { useClient } from "utils/client";

interface CreateCustomStandardPayload {
  title?: string;
  directive?: string;
  standard_id: string;
}

const createCustomStandard = async (
  client: AxiosInstance,
  payload: CreateCustomStandardPayload
) => {
  const { data } = await client.post<CustomStandard>("/custom-standards", {
    payload,
  });
  return data;
};

export const useCreateCustomStandard = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: CreateCustomStandardPayload) =>
      await createCustomStandard(client, payload),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["customStandards"]);
      },
    }
  );
};
