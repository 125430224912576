import { AxiosInstance } from "axios";
import { useMutation, useQueryClient } from "react-query";

import { useClient } from "utils/client";

interface DeleteCustomStandardProps {
  crn: string;
}

const deleteCustomStandard = async (
  client: AxiosInstance,
  { crn }: DeleteCustomStandardProps
) => {
  await client.delete(`/custom-standards/${crn}`);
};

export const useDeleteCustomStandard = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: DeleteCustomStandardProps) =>
      await deleteCustomStandard(client, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["customStandards"]);
      },
    }
  );
};
