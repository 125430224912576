import { AxiosInstance } from "axios";
import { useQuery } from "react-query";

import { CustomStandard } from "types";
import { useClient } from "utils/client";

const fetchCustomStandards = async (client: AxiosInstance) => {
  const { data } = await client.get<CustomStandard[]>("/custom-standards");

  return data;
};

export const useCustomStandards = () => {
  const client = useClient();

  const { data: customStandards = [], isLoading: fetchInProgress } = useQuery(
    ["customStandards"],
    async () => await fetchCustomStandards(client)
  );

  return {
    customStandards,
    fetchInProgress,
  };
};
