import Highlighter from "react-highlight-words";
import { DebounceInput } from "react-debounce-input";
import React, { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { TranslatedStandard } from "types";
import { getRelativeTime } from "utils/date";
import { useAllTranslatedStandards } from "hooks/useStandards";
import { Directive } from "pages/Backoffice/Questionaire/types";
import { CreateCustomStandardOnDashboard } from "components/CustomStandards/CreateCustomStandardOnDashboard";

const DummySearch = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="mt-3 sm:mt-0 sm:m-4">
      <label htmlFor="search_candidate" className="sr-only">
        Search
      </label>
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <DebounceInput
            minLength={3}
            type="text"
            name="search_candidate"
            id="search_candidate"
            className="focus:ring-cyan-500 focus:border-cyan-500 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
            placeholder={t("standards.search.inputPlaceholder")}
            onChange={props.onChange}
            debounceTimeout={300}
          />
        </div>
      </div>
    </div>
  );
};

interface StandardSearchProps {
  directive?: Directive;
  onSelect?: (s: TranslatedStandard) => void;
}

export const StandardSearch = (props: StandardSearchProps) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("");
  const [originalInput, setOriginalInput] = React.useState("");
  const [selection, setSelection] = React.useState<TranslatedStandard[]>([]);

  const { translations } = useAllTranslatedStandards();

  const searchmatches = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value;
      const searchVal = input.toLowerCase();
      const regexVal = searchVal.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

      let sel = translations!.filter((set: any) => {
        return (
          set.content.toLowerCase().match(regexVal) ||
          [set.name, set.latest].join(":").toLowerCase().match(regexVal)
        );
      });

      if (input === "") {
        sel = [];
      }

      setSelection(sel);
      setValue(searchVal);
      setOriginalInput(input);
    },
    [translations]
  );

  useEffect(() => {
    searchmatches({ target: { value } } as any);
  }, [translations, searchmatches /* don't add the value attribute here */]);

  const handleStandardClick = (standardName: string) => {
    const selectedStandard = translations!.find((s) => s.name === standardName);

    if (props.onSelect && selectedStandard) {
      props.onSelect(selectedStandard);
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mx-4 mt-4 mb-1">
        {t("standards.search.headline")}
      </h1>
      <p className="text-sm text-gray-600 leading-relaxed mx-4 mb-4">
        {t("standards.search.subline")}
      </p>
      <DummySearch onChange={searchmatches} />
      <div className="rounded-md border border-cyan-600 m-4">
        <div className="m-4 flex justify-between items-center">
          <span>
            {selection.length > 0 ? (
              t("standards.search.results") + ` (${selection.length})`
            ) : (
              <div className="text-sm text-gray-600 ">
                {t("standards.search.noResults")}

                {value.length >= 3 && (
                  <>
                    {" "}
                    <Trans
                      i18nKey="customStandard.search.no_results"
                      values={{ searchTerm: originalInput }}
                      components={{ italic: <i />, bold: <strong /> }}
                    ></Trans>
                    <CreateCustomStandardOnDashboard
                      button={(props) => (
                        <button
                          {...props}
                          className="inline-flex items-center px-2 py-1 border border-transparent text-xs leading-4 font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                        >
                          {t("customStandard.create_new")}
                        </button>
                      )}
                      initialValues={{
                        title: "",
                        standard_id: originalInput,
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </span>
        </div>
        <ul className="divide-y divide-gray-200 max-h-56 overflow-y-scroll">
          {selection.length > 0 &&
            selection.map((el) => (
              <div
                key={el.name + el.directive}
                onClick={() => handleStandardClick(el.name)}
              >
                <li
                  className={`py-4 flex cursor-pointer hover:bg-cyan-300 ${
                    el.retracted ? "opacity-60" : ""
                  }`}
                >
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <Highlighter
                        className={`${el.retracted ? "line-through" : ""}`}
                        highlightClassName="bg-cyan-600 text-white px-1"
                        searchWords={[value]}
                        autoEscape={true}
                        textToHighlight={[el.name, el.latest].join(":")}
                      />
                      {el.retracted && (
                        <span className="text-xs text-gray-500">
                          {" "}
                          Zurückgezogen
                        </span>
                      )}
                      <span className="text-xs text-gray-500">
                        {" "}
                        {t("standards.search.last_refresh", {
                          relativeTime: getRelativeTime(
                            new Date(el.lastRecorded).getTime()
                          ),
                        })}
                      </span>
                    </p>
                    {el.content && (
                      <p className="text-sm text-gray-500">
                        <Highlighter
                          highlightClassName="bg-cyan-600 text-white px-1"
                          searchWords={[value]}
                          autoEscape={true}
                          textToHighlight={el.content}
                        />
                      </p>
                    )}
                    {el.directive && (
                      <p className="text-sm">
                        <span>{t("standards.search.table.directive")}: </span>
                        <span className="text-gray-500">
                          {t(`steps.questionaire.${el.directive}.label`, {
                            defaultValue: el.directive,
                          })}
                        </span>
                      </p>
                    )}
                    {el.type && (
                      <p className="text-sm">
                        <span>Typ: </span>
                        <span className="text-gray-500">{el.type}</span>
                      </p>
                    )}
                    {el.versions.length > 0 && (
                      <p className="text-sm">
                        <span>Versionen der Norm: </span>
                        <span className="text-gray-500">
                          {el.versions.join(", ")}
                        </span>
                      </p>
                    )}
                  </div>
                </li>
              </div>
            ))}
        </ul>
      </div>
    </div>
  );
};
