import parse from "html-react-parser";
import { TFunction, getI18n } from "react-i18next";

import { QuestionaireItem } from "pages/Backoffice/Questionaire/hooks";
import {
  FormFieldType,
  FormField,
} from "@slootsantos/certain-forms/dist/types";

import { TranslatedStandard } from "types";
import Collapsible from "components/Collapsible/Collapsible";
import { CollapsibleProps } from "components/Collapsible/types";
import { AnnexSection, Directive } from "pages/Backoffice/Questionaire/types";

export interface FormFieldWithCollapsible extends FormField {
  collapsibleItems?: CollapsibleProps[];
}

export const getExitQuestions = (
  questionaireItems: QuestionaireItem[],
  directive: Directive = Directive._none
) => {
  return questionaireItems
    .filter(
      (q) =>
        q.is_exit_question &&
        // TEMP: Join MRL & non-classified
        ((directive === Directive._none && q.directive === Directive.mrl) ||
          q.directive === directive)
    )
    .sort((a, b) => a.ref_number.localeCompare(b.ref_number));
};

export const getFirstLevelQuestions = (
  questionaireItems: QuestionaireItem[],
  directive: Directive = Directive._none
) => {
  return questionaireItems
    .filter(
      (q) =>
        q.is_first_level &&
        // TEMP: Join MRL & non-classified
        ((directive === Directive._none && q.directive === Directive.mrl) ||
          q.directive === directive)
    )
    .sort((a, b) => a.ref_number.localeCompare(b.ref_number));
};

const getQuestionName = (questionaireItem: QuestionaireItem | AnnexSection) =>
  questionaireItem.resource_id!.toString() + "field";

const getQuestionLabel = (
  questionaireItem: QuestionaireItem,
  language = "DE"
) =>
  questionaireItem.questions?.find((question) => question.language === language)
    ?.content || "";

const getAnnexLabel = (questionaireItem: AnnexSection, language = "DE") =>
  `${questionaireItem.name}) ` +
    questionaireItem.questions?.find(
      (question) => question.language === language
    )?.content || "";

const getQuestionExplanation = (
  questionaireItem: QuestionaireItem,
  language = "DE"
) => {
  const explanation =
    questionaireItem.explanations?.find(
      (explanations) => explanations.language === language
    )?.content || "";

  return explanation === "<p><br></p>" ? "" : explanation;
};

const getAnnexExplanation = (annexItem: AnnexSection, language: string) =>
  annexItem.explanations?.find(
    (explanation) => explanation.language === language
  )?.content || "";

const getAnnexInfo = (annexItem: AnnexSection, language: string) =>
  annexItem.infos?.find((explanation) => explanation.language === language)
    ?.content || "";

const getOriginalText1 = (item: QuestionaireItem, language: string) => {
  const content =
    item.originalTexts1?.find((org) => org.language === language)?.content ||
    "";

  return content === "<p><br></p>" ? "" : content;
};

const getOriginalText2 = (item: QuestionaireItem, language: string) => {
  const content =
    item.originalTexts2?.find((org) => org.language === language)?.content ||
    "";

  return content === "<p><br></p>" ? "" : content;
};

const getAnnexOriginalText = (annex: AnnexSection, language: string) => {
  return (
    annex.fullTexts?.find((text) => text.language === language)?.content || ""
  );
};

const getTranslation = (text: string) => {
  return getI18n().t(text);
};

const getCollabsibleItems = (
  explanation: string,
  originalTexts1: string,
  originalTexts2?: string
): CollapsibleProps[] => {
  let arr: CollapsibleProps[] = [];
  if (explanation.length > 0) {
    arr.push({
      label: getTranslation("steps.questionaire.explanation"),
      child: <div className="whitespace-pre-line">{parse(explanation)}</div>,
    });
  }

  if (originalTexts1.length > 0) {
    arr.push({
      label: getTranslation("steps.questionaire.original_text"),
      child: (
        <div className="whitespace-pre-line">
          <>{parse(originalTexts1)}</>
          {originalTexts2 !== undefined && originalTexts2.length > 0 ? (
            <>
              <br />
              <>{parse(originalTexts2)}</>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    });
  }

  return arr;
};

export const buildQuestionFields = (
  questionaireItems: QuestionaireItem[],
  language = "DE"
): FormFieldWithCollapsible[] => {
  return questionaireItems.map((q, idx) => ({
    name: getQuestionName(q),
    label: getQuestionLabel(q, language),
    explanation: getQuestionExplanation(q, language),
    type: FormFieldType.radio,
    collapsibleItems: getCollabsibleItems(
      getQuestionExplanation(q, language),
      getOriginalText1(q, language),
      getOriginalText2(q, language)
    ),
    groupName: (idx % 5).toString(),
    required: true,
    options: [
      { label: "Ja", value: "1" },
      { label: "Nein", value: "0" },
    ],
  }));
};

interface AnnexSectionWithCollapsible extends AnnexSection {
  collapsibleItems?: CollapsibleProps[];
}

export const buildAnnexFields = (
  annexSections: AnnexSectionWithCollapsible[],
  language = "DE",
  groups: AnnexSection[],
  translator: TFunction
): FormFieldWithCollapsible[] => {
  const revGroups = groups.reverse();

  return annexSections.map((annex) => {
    return {
      name: getQuestionName(annex),
      label: getAnnexLabel(annex, language),
      explanation: getAnnexInfo(annex, language),
      type: annex.is_informational
        ? FormFieldType.informational
        : FormFieldType.check,
      customRenderOverride: () => {
        return (
          <div className="col-span-12">
            <span className="text-lg">
              {annex.name}{" "}
              {annex.questions.find((q) => q.language === language)?.content}
            </span>
            {annex.fullTexts?.length > 0 && (
              <Collapsible
                label={translator("steps.questionaire.original_text")}
                child={
                  <p>
                    {parse(
                      annex.fullTexts?.find((q) => q.language === language)
                        ?.content || ""
                    )}
                  </p>
                }
              />
            )}
          </div>
        );
      },
      collapsibleItems: getCollabsibleItems(
        getAnnexExplanation(annex, language),
        getAnnexOriginalText(annex, language)
      ),
      groupName: revGroups.find((g) => annex.name.match(`^${g.name}`))?.name,
    };
  });
};

export function onlyUnique(
  value: number | string,
  index: number | string,
  self: (number | string)[]
) {
  return self.indexOf(value) === index;
}

export interface GroupTranslatedStandard {
  standards: TranslatedStandard[];
  relatedSection: AnnexSection;
}

export const buildStandardFields = (
  standardsGroupping: GroupTranslatedStandard[]
) => {
  return standardsGroupping.flatMap((group) => {
    const flatStandards =
      group.standards?.map((st) => {
        const standardWithVersion = [st.name, st.latest].join(":");

        return {
          name: st.name,
          label: `[${standardWithVersion}] ${st.content}`,
          type: FormFieldType.check,
          groupName: group.relatedSection.name,
        };
      }) || [];

    return flatStandards;
  });
};

export function uniqueArrayByProperty<T, K extends keyof T>(
  items: T[],
  property: K
): T[] {
  const seenProperties = new Set<T[K]>();
  return items.filter((item) => {
    const propValue = item[property];
    if (!seenProperties.has(propValue)) {
      seenProperties.add(propValue);
      return true;
    }
    return false;
  });
}

// This function filters out standards that have no version part, and keeps only the best candidate for each prefix.
export function filterStandardsWithVersions(arr: string[]): string[] {
  // We'll use a map to store the best candidate for each prefix.
  // The key is the substring before the first colon.
  const resultMap: { [prefix: string]: string } = {};

  for (const item of arr) {
    // Find the index of the first colon
    const colonIndex = item.indexOf(":");
    // If there is no colon, treat the whole string as the prefix.
    const prefix = colonIndex >= 0 ? item.slice(0, colonIndex) : item;
    // Check if the item contains a valid version part (non-empty after the colon)
    const hasVersion =
      colonIndex >= 0 && item.slice(colonIndex + 1).trim() !== "";

    // If we've already seen this prefix
    if (prefix in resultMap) {
      // Check if the current candidate in the map has a version part.
      const existingItem = resultMap[prefix];
      const existingHasVersion =
        existingItem.indexOf(":") >= 0 &&
        existingItem.slice(existingItem.indexOf(":") + 1).trim() !== "";
      // If the stored item does NOT have a valid version but the current one does,
      // we replace it.
      if (!existingHasVersion && hasVersion) {
        resultMap[prefix] = item;
      }
    } else {
      // Otherwise, add the current item for this prefix.
      resultMap[prefix] = item;
    }
  }

  // Return the filtered values.
  return Object.values(resultMap);
}
