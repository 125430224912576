import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { BasePage } from "components/BasePage/BasePage";
import { useTranslation } from "react-i18next";
import { SortableTable } from "components/SortableProjectsTable";
import { Sorting } from "types";
import { sorting } from "utils/sorting";
import { SearchInput } from "components/inputs/SearchInput";
import { CustomStandard } from "types";
import { CustomStandardRow } from "components/CustomStandards/Row";
import { CreateCustomStandardOnDashboard } from "components/CustomStandards/CreateCustomStandardOnDashboard";
import { useCustomStandards } from "components/CustomStandards/hooks/useCustomStandards";

enum CustomStandardColumn {
  title = "title",
  directive = "directive",
  standardId = "standardId",
}

interface ColumnSortConfig<T> {
  accessor: (item: T) => string;
}

export const CustomStandards = () => {
  const { t } = useTranslation();
  const { customStandards, fetchInProgress } = useCustomStandards();

  const [filteredData, setFilteredData] = useState<CustomStandard[]>(
    customStandards || []
  );
  const [sortOrder, setSortOrder] = useState<Sorting>(Sorting.asc);
  const [sortColumn, setSortColumn] = useState<string | null>(null);

  const columnSortMap: Record<
    CustomStandardColumn,
    ColumnSortConfig<CustomStandard>
  > = {
    [CustomStandardColumn.title]: {
      accessor: (standard) => standard.title,
    },
    [CustomStandardColumn.directive]: {
      accessor: (standard) => standard.directive,
    },
    [CustomStandardColumn.standardId]: {
      accessor: (standard) => standard.standard_id,
    },
  };

  const getSortedData = (
    sortOrder: string | null,
    sortColumn: string | null
  ) => {
    if (!filteredData || filteredData.length === 0)
      return customStandards || [];

    const sortedData = filteredData.slice().sort((a, b) => {
      return sorting(
        a,
        b,
        sortOrder as Sorting,
        sortColumn as CustomStandardColumn,
        columnSortMap
      );
    });

    return sortedData;
  };

  useEffect(() => {
    setFilteredData(customStandards || []);
  }, [customStandards]);

  return (
    <BasePage
      loading={fetchInProgress}
      breadcrumbItems={[
        { label: "Dashboard", location: "/custom-standards" },
        {
          label: t("form.custom_standard.list"),
          location: "/custom-standards",
        },
      ]}
    >
      <main className="flex-1 relative z-0 focus:outline-none" tabIndex={0}>
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
              {t("form.custom_standard.list")}
            </h1>
          </div>
          {customStandards?.length > 0 && (
            <div className="flex flex-row gap-1">
              <CreateCustomStandardOnDashboard
                button={(props) => (
                  <button
                    onClick={props.onClick}
                    type="button"
                    className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:order-1 sm:ml-3"
                  >
                    {t("customStandard.create_new")}
                  </button>
                )}
              />
            </div>
          )}
        </div>

        {customStandards?.length > 0 && (
          <>
            <SearchInput
              onChange={setFilteredData}
              placeholder={"customStandard.search.placeholder"}
              values={customStandards}
              fields={["title", "standard_id"]}
              noMatchTranslation={"customStandard.search_no_results_label"}
            />

            <SortableTable
              data={getSortedData(sortOrder, sortColumn)}
              columns={[
                {
                  label: t("customStandard.standardId"),
                  name: CustomStandardColumn.standardId,
                  sortable: true,
                  start: true,
                },
                {
                  label: t("customStandard.title"),
                  name: CustomStandardColumn.title,
                  sortable: true,
                },
                {
                  label: t("customStandard.directive"),
                  name: CustomStandardColumn.directive,
                  sortable: true,
                },
              ]}
              sort={getSortedData}
              renderRow={(standard) => (
                <CustomStandardRow key={standard.crn} {...standard} />
              )}
            />
          </>
        )}

        {!customStandards?.length && <EmptyDashboard />}
      </main>
    </BasePage>
  );
};

const EmptyDashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center border-2 border-gray-300 border-dashed rounded-lg p-8">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        {t("customStandard.search.no_standards")}
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        {t("customStandard.search.create_standard_subline")}
      </p>
      <div className="mt-6">
        <CreateCustomStandardOnDashboard
          button={(props) => (
            <button
              onClick={props.onClick}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              {t("customStandard.create_new")}
            </button>
          )}
        />
      </div>
    </div>
  );
};

export default CustomStandards;
