import i18n from "i18n";
import parse from "html-react-parser";
import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import {
  QuestionaireItem,
  useQuestionaireItems,
  useQuestionaireItemsForProject,
} from "pages/Backoffice/Questionaire/hooks";
import {
  Form as FormType,
  Certification as FormCertfication,
} from "@slootsantos/certain-forms/dist/types";
import {
  useAnnexSections,
  useAnnexSectionsForProject,
} from "pages/Backoffice/Questionaire/hooks/useAnnexSections";

import Form from "forms/Form";
import { Project } from "types";
import { useClient } from "utils/client";
import { Modal } from "components/Modal/Modal";
import { ControlContext } from "context/Controls";
import { Loading } from "components/Loading/Loading";
import { useTranslatedStandards } from "hooks/useStandards";
import Collapsible from "components/Collapsible/Collapsible";
import { Directive } from "pages/Backoffice/Questionaire/types";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { useProjectDetails } from "pages/Details/hooks/useProjectDetails";
import {
  buildAnnexFields,
  getExitQuestions,
  buildQuestionFields,
  uniqueArrayByProperty,
  buildStandardFields,
  GroupTranslatedStandard,
  onlyUnique,
} from "./util/baseHelpers";

// REMEMBER:
// There is primary and secondary directives.
// Primary directives are the ones driving the product category!
// E.g. MRL and LVD are primary. Hence they can **never** be together!
// Whereas the EMV is a secondary directive and can be combined with either MRL or LVD!
// With primary directives there is always one trumping the other!

interface DirectiveSearchRouteParams {
  projectId: string;
}

export const EmvResearch = () => {
  const client = useClient();
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const langCode = i18n.language.split("-")[0].toUpperCase();
  const { directive } = useParams<{ directive: Directive }>();
  const { displayNotification } = React.useContext(ControlContext);

  const { projectId } = useParams<DirectiveSearchRouteParams>();
  const { project, fetchInProgress } = useProjectDetails(projectId);

  const { annexSections } = useAnnexSections();
  const [fd, setFd] = React.useState<any>(null);
  const [preloaded, setPreloaded] = React.useState(false);
  const [defaultData, setDefaultData] = React.useState<any>({});
  const questionaireItemsUnsorted = useQuestionaireItems();

  const {
    annexSections: annexSectionsProject,
    isLoading: annexSectionLoading,
  } = useAnnexSectionsForProject(projectId);

  const {
    questionaireItems: questionaireItemsProject,
    isLoading: questionaireLoading,
  } = useQuestionaireItemsForProject(projectId);

  const questionaireItems = questionaireItemsUnsorted?.sort((a, b) =>
    a.ref_number?.localeCompare(b.ref_number)
  );
  const standardsProject = project?.standards;
  const { translations } = useTranslatedStandards(Directive.emv);

  const [isInformationModalVisible, setIsInformationModalVisible] =
    React.useState(false);
  const [isExitModalVisible, setIsExitModalVisible] = React.useState(false);
  const [exitModalContent, setExitModalContent] =
    React.useState<QuestionaireItem>();

  React.useEffect(() => {
    if (!questionaireItems?.length) return;

    const translationDirective =
      directive === undefined || directive === Directive._none
        ? "mrl"
        : directive;

    const exitQuestions = getExitQuestions(questionaireItems, directive);

    // TODO: PASS AS PROP
    const formD: FormType = {
      type: FormCertfication.ce,
      documents: [],
      directives: [],
      sections: [
        {
          label: t(`form.${translationDirective}.exitQuestions.label`),
          name: "exitQuestions",
          info: t(`form.${translationDirective}.exitQuestions.info`),
          subline: t(`form.${translationDirective}.exitQuestions.subline`),
          fields: buildQuestionFields(exitQuestions, langCode),
          isGroup: false,
        },
        {
          label: t(`form.${translationDirective}.Anhang1Relevante.label`),
          name: "Anhang1Relevante",
          subline: t(""),
          info: t(`form.${translationDirective}.Anhang1Relevante.info`),
          fields: [],
        },
        {
          label: t(`form.${translationDirective}.Normenauswahl.label`),
          name: "Normenauswahl",
          info: t(`form.${translationDirective}.Normenauswahl.info`),
          subline: t(`form.${translationDirective}.Normenauswahl.subline`),
          fields: [],
        },
      ],
    };

    setFd(formD);
  }, [questionaireItems, langCode, t, directive]);

  // TODO: PASS AS PROP
  const handleSave = React.useCallback(
    async (form: any) => {
      const exitResponses = form?.exitQuestions
        ? Object.keys(form.exitQuestions)
            .filter((exitKey) => form?.exitQuestions[exitKey] !== "")
            .map((exitKey) => {
              const id = exitKey.split("field")[0];
              return {
                id,
                isSelected: Boolean(parseInt(form.exitQuestions[exitKey])),
              };
            })
        : [];

      const questionaireIds = [...exitResponses].map(
        ({ id, isSelected, ...r }) => ({
          questionaireId: id,
          isSelected: isSelected,
        })
      );

      const annexIds = form.Anhang1Relevante
        ? Object.keys(form.Anhang1Relevante)
            .map((anhangKey) => {
              const id = anhangKey.split("field")[0];
              return {
                id,
                isSelected: Boolean(parseInt(form.Anhang1Relevante[anhangKey])),
              };
            })
            .map(({ id, isSelected }) => ({ annexId: id, isSelected }))
        : [];

      const uniqueQuestionaires = uniqueArrayByProperty(
        [...questionaireIds, ...questionaireItemsProject?.questionaires!],
        "questionaireId"
      );

      await client.post(
        `/projects/${projectId}/questionaireItems`,
        uniqueQuestionaires
      );
      await client.post(`/projects/${projectId}/annexSections`, annexIds);

      queryClient.refetchQueries(["projects", projectId]);
      queryClient.refetchQueries(["questionaireItems", projectId]);
      queryClient.refetchQueries(["annexSections", projectId]);

      displayNotification!(
        t("notification.saving.generic_success"),
        t("notification.saving.research_success")
      );
    },
    [
      client,
      projectId,
      queryClient,
      translations,
      displayNotification,
      questionaireItemsProject,
    ]
  );

  // TODO: PASS AS PROP
  const handleSubmit = useCallback(
    async (form: any) => {
      const s = form.Normenauswahl
        ? Object.keys(form.Normenauswahl)
            .filter((norm) => Boolean(parseInt(form.Normenauswahl[norm])))
            .map((n) => {
              const completeStandards = translations?.find(
                (tr) => tr.name === n
              );

              return [n, completeStandards?.latest].join(":");
            })
        : [];

      const uniqueStds = [...(standardsProject || []), ...s].filter(onlyUnique);

      await client.put(`/projects/${projectId}`, {
        payload: {
          ...project,
          standards: uniqueStds,
          directives: project!.directives.map((d) => {
            if (d.name === directive) {
              return {
                ...d,
                is_exit: false,
                completed_research: true,
              };
            }

            return d;
          }),
        } as Project,
      });

      setIsInformationModalVisible(true);
      await handleSave(form);
    },
    [
      projectId,
      handleSave,
      client,
      project,
      directive,
      standardsProject,
      translations,
    ]
  );

  // TODO: PASS AS PROP
  const handleStepCallback = React.useCallback(
    (v: any, currentStep: number, _: any, isPreload?: boolean) => {
      if (!fd?.sections?.length || !translations?.length || !v.exitQuestions)
        return;
      const shouldExit = Object.values(v.exitQuestions).some(
        (value) => value === "1"
      );

      const exitQuestion = Object.keys(v.exitQuestions)
        ?.filter((key) => v.exitQuestions[key] === "1")
        ?.map((key) => key.split("field")[0])
        ?.map((keyId) =>
          questionaireItems.find(({ resource_id }) => resource_id === keyId)
        );

      if (shouldExit) {
        const preventNextStepFromCallback = true;

        setIsExitModalVisible(true);
        setExitModalContent(exitQuestion[0]);

        return preventNextStepFromCallback;
      }

      const annexes = annexSections.filter((q) => q.directive === directive);
      const requiredAnnex = annexSections.filter(
        (qi) => qi.name.match(/^1\./) || qi.name.match(/^0\./)
      )!;

      const allSortedAnnexes = [...requiredAnnex, ...annexes].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true })
      )!;

      const _groupsByDirective = allSortedAnnexes.filter(
        (annex) =>
          annex.directive === directive &&
          annex.is_informational &&
          annex.name.length < 3 // matches "1." or "2."
      );

      const _groups = uniqueArrayByProperty(_groupsByDirective, "name");

      const _noGroupByDirective = allSortedAnnexes.filter(
        (annex) =>
          annex.directive === directive &&
          (!annex.is_informational ||
            (annex.is_informational && annex.name.length >= 3)) // matches "1.1." or "2.1."
      );

      const _noGroup = uniqueArrayByProperty(_noGroupByDirective, "name");

      fd.sections[1] = {
        ...fd.sections[1],
        fields: [...buildAnnexFields(_noGroup, langCode, _groups, t)],
        isGroup: true,
        groups: _groups.reverse().map((group) => {
          return {
            name: group.name,
            label: `${group.name} ${
              group.questions.find((q) => q.language === langCode)?.content
            }`,
          };
        }),
      };

      const annexIds = v.Anhang1Relevante
        ? Object.keys(v.Anhang1Relevante)
            .map((anhangKey) => {
              const id = anhangKey.split("field")[0];
              return {
                id,
                isSelected: Boolean(parseInt(v.Anhang1Relevante[anhangKey])),
              };
            })
            .map(({ id, isSelected }) => ({ annexId: id, isSelected }))
        : [];

      const selectedQuestionaires = annexIds.filter((uq) => uq.isSelected);
      const selectedQuestionairesToAnnex = selectedQuestionaires
        .map((uq) => {
          const annex = annexSections.find(
            (as) => as.resource_id === uq.annexId
          );

          return annex;
        })
        .filter((uq) => !!uq);

      const relevantStandardsNames = selectedQuestionairesToAnnex.flatMap(
        (as) => as.suggested_standards
      );

      const relevantStandards =
        relevantStandardsNames
          ?.filter(onlyUnique)
          ?.map(
            (name) =>
              translations!.find((s) => s?.name === name?.split(":")[0])!
          )
          .filter(Boolean) || [];

      const alreadyUsedStandards: string[] = [];
      const groups: GroupTranslatedStandard[] = selectedQuestionairesToAnnex
        .map((as) => {
          const standards = relevantStandards?.filter(
            (n) =>
              as.suggested_standards?.includes(n.name) &&
              !alreadyUsedStandards.includes(n.name)
          );

          alreadyUsedStandards.push(...standards.map((std) => std.name));

          return {
            standards,
            relatedSection: as,
          };
        })
        .filter(Boolean);

      fd.sections[2].fields = [...buildStandardFields(groups)];
      fd.sections[2].isGroup = true;
      fd.sections[2].groups = groups
        .filter((sg) => sg.standards.length)
        .map(({ relatedSection }) => ({
          name: relatedSection.name,
          label:
            relatedSection.questions.find((q) => q.language === langCode)
              ?.content || "",
        }));

      const n = { ...fd };
      setFd(n);
    },
    [t, fd, langCode, directive, translations, annexSections, questionaireItems]
  );

  React.useEffect(() => {
    if (
      !questionaireItems?.length ||
      !questionaireItemsProject ||
      !annexSectionsProject ||
      fetchInProgress
    ) {
      return;
    }

    const rawExitQuestions = getExitQuestions(questionaireItems, directive);
    const exitQuestionIds = rawExitQuestions.map((q) => q.resource_id!);

    const exitQuestions = questionaireItemsProject?.questionaires
      ?.filter((q) => exitQuestionIds.includes(q.questionaireId))
      .reduce((acc, key) => {
        acc[key.questionaireId.toString() + "field"] = key.isSelected
          ? "1"
          : "0";

        return acc;
      }, {} as Record<string, string>);

    const annex = annexSectionsProject?.annexSections?.reduce((acc, key) => {
      acc[key.annexId.toString() + "field"] = key.isSelected ? "1" : "0";

      return acc;
    }, {} as Record<string, string>);

    const norm = standardsProject?.reduce((acc, key) => {
      const stdWithoutVersion = key.split(":")[0];
      acc[stdWithoutVersion] = "1";

      return acc;
    }, {} as Record<string, string>);

    const defaultData = {
      exitQuestions,
      Anhang1Relevante: annex,
      Normenauswahl: norm,
    };

    setDefaultData(defaultData);

    if (
      defaultData &&
      !preloaded &&
      translations?.length &&
      fd?.sections.length
    ) {
      handleStepCallback(defaultData, 0, null, true);
      setPreloaded(true);
    }
  }, [
    fd,
    directive,
    preloaded,
    translations,
    fetchInProgress,
    standardsProject,
    questionaireItems,
    handleStepCallback,
    annexSectionsProject,
    questionaireItemsProject,
  ]);

  if (
    !fd?.sections ||
    questionaireLoading ||
    annexSectionLoading ||
    (questionaireItemsProject?.questionaires && !defaultData.exitQuestions) ||
    (annexSectionsProject?.annexSections &&
      (!defaultData.Anhang1Relevante ||
        !Object.keys(defaultData.Anhang1Relevante).length))
  ) {
    return <Loading />;
  }

  return (
    <div className="questionaire">
      <Form
        persistStep
        formData={fd}
        onSubmit={handleSubmit}
        onAutosave={handleSave}
        defaultValues={defaultData}
        onStep={handleStepCallback}
        disableStepper={!defaultData.exitQuestions}
      />
      {/* -------------------------- EXIT MODAL ------------------------------------ */}
      <Modal
        onClose={() => setIsExitModalVisible(false)}
        show={isExitModalVisible}
        width="1/2"
        content={
          <div>
            <span className="text-xl">
              {t(`questionaire.${directive}.exit_modal.headline`)}
            </span>
            <div className="pt-4"></div>
            <div>
              <p>{t(`questionaire.${directive}.exit_modal.subline`)}</p>
              <Collapsible
                label={`${t(
                  `questionaire.${directive}.exit_modal.collapsible_label`
                )} ${exitModalContent?.article}`}
                child={
                  <>
                    <p>
                      <b>
                        Frage: "
                        {
                          exitModalContent?.questions?.find(
                            (t) => t.language === langCode
                          )?.content
                        }
                        "
                      </b>
                    </p>
                    {parse(
                      exitModalContent?.originalTexts1?.find(
                        (t) => t.language === langCode
                      )?.content || ""
                    )}
                  </>
                }
              />
              <p className="pt-4 pb-4">
                {t(`questionaire.${directive}.exit_modal.explanation`)}
              </p>

              <div className="pt-9 text-right">
                <a
                  className="px-6 text-sm text-gray-500 hover:text-gray-700 cursor-pointer"
                  onClick={() => setIsExitModalVisible(false)}
                >
                  {t(`questionaire.${directive}.exit_modal.cancel`)}
                </a>
                <PrimaryButton
                  onClick={async () => {
                    await client.put(`/projects/${projectId}`, {
                      payload: {
                        ...project,
                        directives: project!.directives.map((d) => {
                          if (d.name === directive) {
                            return {
                              ...d,
                              is_exit: true,
                              completed_research: true,
                            };
                          }

                          return d;
                        }),
                        projectdata: {
                          ...project!.projectdata,
                        },
                      } as Project,
                    });

                    await client.post(
                      `/projects/${projectId}/questionaireItems`,
                      [
                        ...(questionaireItemsProject?.questionaires || []),
                        {
                          questionaireId: exitModalContent?.resource_id,
                          isSelected: true,
                        },
                      ]
                    );

                    await queryClient.refetchQueries(["projects", projectId]);
                    await queryClient.invalidateQueries([
                      "questionaireItems",
                      projectId,
                    ]);

                    history.push(`/projects/${projectId}?saved=true`);
                  }}
                >
                  {t(`questionaire.${directive}.exit_modal.cta`)}
                </PrimaryButton>
              </div>
            </div>
          </div>
        }
      />
      {/* -------------------------- INFO MODAL ------------------------------------ */}
      <Modal
        onClose={() => setIsInformationModalVisible(false)}
        show={isInformationModalVisible}
        width="1/2"
        content={
          <div>
            <span className="text-xl">
              <Trans
                i18nKey={`questionaire.${directive}.info_modal.headline`}
              />
            </span>
            <div className="pt-4"></div>
            <div>
              <p className="pt-4 pb-4">
                <Trans
                  i18nKey={`questionaire.${directive}.info_modal.explanation`}
                >
                  <a
                    href="https://webgate.ec.europa.eu/single-market-compliance-space/notified-bodies/by-country"
                    target="_blank"
                    rel="noreferrer"
                    className="text-cyan-500 underline"
                  >
                    conent
                  </a>
                </Trans>
              </p>

              <div className="pt-9 text-right">
                <PrimaryButton
                  onClick={async () => {
                    history.push(`/projects/${projectId}?saved=true`);
                  }}
                >
                  {t(`questionaire.${directive}.info_modal.cta`)}
                </PrimaryButton>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};
