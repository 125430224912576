import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BasePage } from "components/BasePage/BasePage";
import Form from "forms/Form";
import { useCustomStandard } from "components/CustomStandards/hooks/useCustomStandard";
import { useUpdateCustomStandard } from "components/CustomStandards/hooks/useUpdateCustomStandard";
import {
  FormFieldType,
  FormSection,
} from "@slootsantos/certain-forms/dist/types";
import { CustomStandard } from "types";

interface RouteParams {
  standardId: string;
}

const customStandardSection: FormSection = {
  name: "custom_standard",
  label: "form.custom_standard.edit",
  subline: "form.custom_standard.edit_description",
  fields: [
    {
      name: "standard_id",
      label: "form.custom_standard.standard_id",
      type: FormFieldType.full,
      required: true,
    },
    {
      name: "title",
      label: "form.custom_standard.title",
      type: FormFieldType.full,
      required: false,
    },
    {
      name: "directive",
      label: "form.custom_standard.directive",
      type: FormFieldType.full,
      required: false,
    },
  ],
};

export const EditCustomStandard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { standardId } = useParams<RouteParams>();
  const { customStandard, isLoading } = useCustomStandard(standardId);
  const { mutate: updateCustomStandard } = useUpdateCustomStandard();

  const handleSubmit = (values: any) => {
    const customStd = values as { custom_standard: CustomStandard };

    updateCustomStandard(customStd.custom_standard, {
      onSuccess: () => {
        history.push("/custom-standards");
      },
    });
  };

  return (
    <BasePage
      loading={isLoading}
      breadcrumbItems={[
        { label: "Dashboard", location: "/custom-standards" },
        {
          label: t("form.custom_standard.list"),
          location: "/custom-standards",
        },
        {
          label: customStandard?.title || standardId,
          location: `/custom-standards/${standardId}`,
        },
      ]}
    >
      <Form
        context="editCustomStandard"
        formData={{ sections: [customStandardSection] }}
        defaultValues={{ custom_standard: customStandard }}
        onSubmit={handleSubmit}
        saveText="form.custom_standard.save"
      />
    </BasePage>
  );
};
