import { useTranslation } from "react-i18next";

import { PDFStatus } from "./types";
import { StatusIndictor } from "./StatusIndicator";
import { DocumentDefinition } from "./hooks/useEnhancedDocuments";

export const DocumentRow = (props: {
  isSelected: boolean;
  document: DocumentDefinition;
  onPreviewDoc: (loc: string) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation();

  return (
    <tr>
      <td className="relative px-7 sm:w-12 sm:px-6">
        {props.isSelected && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-cyan-600" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-600"
          onChange={props.onChange}
          checked={props.isSelected}
        />
      </td>
      <td className="px-6 py-3 max-w-0 whitespace-nowrap text-sm font-medium text-gray-900">
        <div className="flex items-center space-x-3 lg:pl-2">
          <a className="truncate cursor-pointer hover:text-gray-600">
            <span>{t(`documents.${props.document.label}.label`)}</span>
          </a>
        </div>
      </td>
      <td className="px-6 py-3 text-sm text-gray-500">
        {t(`documents.visibility.${props.document.visibility}`)}
      </td>
      <td className="px-6 py-3 text-sm text-gray-500">
        <StatusIndictor status={props.document.status} />
      </td>
      <td className="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-gray-500">
        {props.document.revision}
      </td>
      <td className="pr-6">
        <div className="relative flex justify-end items-center">
          <span
            className="text-cyan-600 hover:text-cyan-900 text-xs cursor-pointer"
            onClick={() => props.onPreviewDoc(props.document.location!)}
          >
            {props.document.status === PDFStatus.ready
              ? t("documents.preview")
              : ""}
          </span>
        </div>
      </td>
      <div className="bg-red-50 bg-blue-50 bg-green-50 bg-gray-50 text-red-700 text-blue-700 text-green-700 hidden"></div>
    </tr>
  );
};
