import React from "react";
import { FormField as FormFieldInterface } from "@slootsantos/certain-forms/dist/types";

import { formFieldMap } from "./formfieldMap";

type FormFieldProps = FormFieldInterface &
  React.InputHTMLAttributes<{ value: string }> &
  React.SelectHTMLAttributes<{ value: string }>;

const FormField: React.FunctionComponent<FormFieldProps> = (props) => {
  const Input = formFieldMap[props.type];

  // @ts-ignore
  return <Input id={props.name} {...props} />;
};

export default FormField;
