import {
  MarkButton,
  HeadingButton,
  ListButton,
  ImageButton,
  MessageBoxButton,
  TableControls,
} from "./toolbar/index";
import { ProjectDataButton } from "./toolbar/ProjectDataButton";
import { Tooltip } from "../../Tooltip";

export {
  MarkButton,
  HeadingButton,
  ListButton,
  ImageButton,
  MessageBoxButton,
  TableControls,
} from "./toolbar/index";

export { toggleMark, isMarkActive } from "./toolbar/utils";

export enum ToolbarGroup {
  Formatting = "formatting",
  Headings = "headings",
  Lists = "lists",
  Insertions = "insertions",
  MessageBoxes = "messageBoxes",
  ProjectData = "projectData",
}

interface ToolbarButton {
  component: React.ReactElement;
  tooltip: string;
}

const allToolbarButtons: Record<ToolbarGroup, ToolbarButton[]> = {
  [ToolbarGroup.Formatting]: [
    {
      component: <MarkButton format="bold" icon="B" />,
      tooltip: "Bold",
    },
    {
      component: <MarkButton format="italic" icon="I" />,
      tooltip: "Italic",
    },
    {
      component: <MarkButton format="underline" icon="U" />,
      tooltip: "Underline",
    },
    {
      component: <MarkButton format="highlight" icon="H" />,
      tooltip: "Highlight",
    },
  ],
  [ToolbarGroup.Headings]: [
    {
      component: <HeadingButton level={1} icon="H1" />,
      tooltip: "Heading 1",
    },
    {
      component: <HeadingButton level={2} icon="H2" />,
      tooltip: "Heading 2",
    },
    {
      component: <HeadingButton level={3} icon="H3" />,
      tooltip: "Heading 3",
    },
    {
      component: <HeadingButton level={4} icon="H4" />,
      tooltip: "Heading 4",
    },
  ],
  [ToolbarGroup.Lists]: [
    {
      component: <ListButton format="bulleted" icon="bullet" />,
      tooltip: "Bullet List",
    },
    {
      component: <ListButton format="numbered" icon="list" />,
      tooltip: "Numbered List",
    },
  ],
  [ToolbarGroup.Insertions]: [
    {
      component: <TableControls />,
      tooltip: "Table Controls",
    },
    {
      component: <ImageButton />,
      tooltip: "Insert Image",
    },
  ],
  [ToolbarGroup.MessageBoxes]: [
    {
      component: <MessageBoxButton format="info" />,
      tooltip: "Info Message",
    },
    {
      component: <MessageBoxButton format="attention" />,
      tooltip: "Attention Message",
    },
    {
      component: <MessageBoxButton format="warning" />,
      tooltip: "Warning Message",
    },
    {
      component: <MessageBoxButton format="danger" />,
      tooltip: "Danger Message",
    },
  ],
  [ToolbarGroup.ProjectData]: [
    {
      component: <ProjectDataButton />,
      tooltip: "Project Data",
    },
  ],
};

interface CustomToolbarProps {
  groups?: ToolbarGroup[];
}

export const CustomToolbar = ({
  groups = Object.values(ToolbarGroup),
}: CustomToolbarProps) => {
  const selectedGroups = groups
    .map((group) => allToolbarButtons[group])
    .filter(Boolean);

  return (
    <div className="border-b border-gray-200 p-2 flex items-center gap-1">
      {selectedGroups.map((group, idx) => (
        <div
          key={idx}
          className="flex items-center gap-1 border-r border-gray-200 pr-2 mr-2"
        >
          {group.map((button) => (
            <Tooltip key={button.tooltip} text={button.tooltip}>
              {button.component}
            </Tooltip>
          ))}
        </div>
      ))}
    </div>
  );
};
