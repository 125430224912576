import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SearchBar from "components/PowerSearch/SearchBar";
import SearchResults from "components/PowerSearch/SearchResults";
import { useSearch } from "components/PowerSearch/hooks/useSearch";
import { MultiStepLoader } from "components/PowerSearch/MultiStepLoader";
import ExampleQueries from "components/PowerSearch/ExampleQueries";

const SearchPage = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [initialQuery, setInitialQuery] = useState<string>();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    setInitialQuery(searchParams.get("q") || "");
  }, [search, setInitialQuery]);

  const {
    query,
    setQuery,
    performSearch,
    results,
    totalResults,
    summary,
    standards,
    executionTimeMs,
    isLoading,
    isError,
    error,
  } = useSearch(initialQuery);

  // Perform search on initial load if query is present
  useEffect(() => {
    // Only perform search on initial load or when URL is updated externally
    // This prevents double execution when the search button is clicked
    if (initialQuery && query !== initialQuery && !isLoading) {
      performSearch(initialQuery);
    }
  }, [initialQuery, performSearch, query, isLoading]);

  // Update URL when search is performed
  const handleSearch = (newQuery: string) => {
    setQuery(newQuery);
    performSearch(newQuery);
  };

  return (
    <div className="py-12">
      <div className="mb-16 relative">
        <div className="absolute inset-0 -z-10 bg-gradient-to-r from-blue-100/30 via-cyan-100/20 to-blue-100/30 blur-3xl opacity-50 rounded-full"></div>
        <div className="flex  items-center justify-center max-w-3xl mx-auto px-6 mb-4">
          <div className="flex items-center">
            <span className="text-4xl font-bold text-gray-800">
              {t("powersearch.searchPage.title")}
            </span>
            <span className="ml-3 px-2.5 py-1 text-xs font-bold text-white rounded-full bg-gradient-to-r from-cyan-800 via-cyan-600 to-cyan-300 flex items-center gap-1">
              PREVIEW
            </span>
          </div>
        </div>
        <p className="text-center text-gray-600 max-w-3xl mx-auto text-lg">
          {t("powersearch.searchPage.subtitle")}
        </p>
      </div>

      <SearchBar
        initialQuery={query}
        onSearch={handleSearch}
        isLoading={isLoading}
      />

      <ExampleQueries onSelectQuery={handleSearch} />

      {isError && (
        <div className="mt-8 p-6 bg-red-50 border border-red-200 rounded-xl text-red-700 shadow-sm">
          <div className="flex items-start gap-3">
            <div className="text-red-500 mt-0.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div>
              <h3 className="font-semibold text-lg">
                {t("powersearch.searchResults.error")}
              </h3>
              <p>
                {error?.message || t("powersearch.searchResults.errorMessage")}
              </p>
            </div>
          </div>
        </div>
      )}

      {isLoading && <MultiStepLoader />}

      {!isLoading && (
        <SearchResults
          results={results}
          totalResults={totalResults}
          summary={summary}
          standards={standards}
          executionTimeMs={executionTimeMs}
          isLoading={isLoading}
          query={query}
        />
      )}
    </div>
  );
};

export default SearchPage;
