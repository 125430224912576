import React from "react";
import { TrashIcon } from "@heroicons/react/solid";
import { Chapter, SubChapter, H3Section, H4Section } from "../types";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

interface ChapterListProps {
  chapters: Chapter[];
  selectedChapter: Chapter | null;
  selectedSubChapter: SubChapter | null;
  onChapterClick: (chapter: Chapter) => void;
  onSubChapterClick: (chapter: Chapter, subChapter: SubChapter) => void;
  onDeleteChapter: (chapter: Chapter, e: React.MouseEvent) => void;
  onDeleteSubChapter: (
    chapter: Chapter,
    subChapter: SubChapter,
    subChapterIndex: number,
    e: React.MouseEvent
  ) => void;
  onAddNewChapter: () => void;
  onChaptersReorder: (reorderedChapters: Chapter[]) => void;
}

export const ChapterList: React.FC<ChapterListProps> = ({
  chapters,
  selectedChapter,
  selectedSubChapter,
  onChapterClick,
  onSubChapterClick,
  onDeleteChapter,
  onAddNewChapter,
  onChaptersReorder,
}) => {
  const handleDragEnd = (result: DropResult) => {
    const { source, destination, type } = result;

    // If dropped outside the list or no movement
    if (
      !destination ||
      (source.index === destination.index &&
        source.droppableId === destination.droppableId)
    ) {
      return;
    }

    if (type === "CHAPTER") {
      const reorderedChapters = Array.from(chapters);
      const [movedChapter] = reorderedChapters.splice(source.index, 1);
      reorderedChapters.splice(destination.index, 0, movedChapter);

      // Update the chapter IDs to reflect their new order
      const updatedChapters = reorderedChapters.map((chapter, index) => {
        const newId = `${index + 1}`;

        // Update subchapter IDs to reflect the new chapter ID
        const updatedSubChapters = chapter.subChapters.map(
          (subChapter, subIndex) => {
            return {
              ...subChapter,
              id: `${newId}.${subIndex + 1}`,
              h3sections: subChapter.h3sections.map((h3section, h3Index) => {
                return {
                  ...h3section,
                  id: `${newId}.${subIndex + 1}.${h3Index + 1}`,
                  subsections: h3section.subsections.map(
                    (h4section, h4Index) => {
                      return {
                        ...h4section,
                        id: `${newId}.${subIndex + 1}.${h3Index + 1}.${
                          h4Index + 1
                        }`,
                      };
                    }
                  ),
                };
              }),
            };
          }
        );

        return {
          ...chapter,
          id: newId,
          subChapters: updatedSubChapters,
        };
      });

      onChaptersReorder(updatedChapters);
    }
  };
  return (
    <div className="w-1/4 bg-white rounded-lg shadow p-4 overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Kapitel</h2>
        <button
          onClick={onAddNewChapter}
          className="px-3 py-1 text-sm rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
        >
          + Neues Kapitel
        </button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="chapters" type="CHAPTER">
          {(provided) => (
            <ul
              className="space-y-2"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {chapters.map((chapter, index) => (
                <Draggable
                  key={chapter.id}
                  draggableId={chapter.id}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      onClick={() => onChapterClick(chapter)}
                    >
                      {/* Chapter (H1) */}
                      <div
                        className={`p-2 rounded cursor-pointer transition-colors ${
                          selectedChapter?.id === chapter.id &&
                          !selectedSubChapter
                            ? "bg-cyan-600 text-white font-semibold"
                            : "hover:bg-gray-100 font-semibold"
                        }`}
                      >
                        <div className="flex justify-between items-center">
                          <div
                            {...provided.dragHandleProps}
                            className="flex items-center"
                          >
                            <div className="mr-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                                />
                              </svg>
                            </div>
                            <span>{`${chapter.id}. ${chapter.title}`}</span>
                          </div>
                          <button
                            onClick={(e) => onDeleteChapter(chapter, e)}
                            className="ml-2 px-2 py-1 text-xs rounded focus:text-white-600 focus:outline-none hover:text-red-500"
                          >
                            <TrashIcon className="flex-shrink-0 h-4" />
                          </button>
                        </div>
                      </div>

                      {/* Subchapters (H2) */}
                      <ul className="ml-4 mt-1 space-y-1">
                        {chapter.subChapters.map((subChapter, index) => (
                          <li key={subChapter.id}>
                            <div
                              className={`p-2 rounded  transition-colors ${
                                selectedSubChapter?.id === subChapter.id
                                  ? "bg-cyan-600 text-white font-semibold"
                                  : ""
                              }`}
                            >
                              <div className="flex justify-between items-center">
                                <span
                                // onClick={() => onSubChapterClick(chapter, subChapter)}
                                >
                                  {`${subChapter.id}. ${subChapter.title}`}
                                </span>
                              </div>
                            </div>

                            {/* H3 Sections */}
                            <ul className="ml-4 mt-1 space-y-1">
                              {subChapter.h3sections.map(
                                (h3section: H3Section) => (
                                  <li key={h3section.id}>
                                    <div className="p-2 rounded cursor-pointer hover:bg-gray-100">
                                      <span className="text-sm">
                                        {`${h3section.id}. ${h3section.title}`}
                                      </span>
                                    </div>

                                    {/* H4 Sections */}
                                    <ul className="ml-4 mt-1 space-y-1">
                                      {h3section.subsections.map(
                                        (h4section: H4Section) => (
                                          <li key={h4section.id}>
                                            <div className="p-2 rounded cursor-pointer hover:bg-gray-100">
                                              <span className="text-sm">
                                                {`${h4section.id}. ${h4section.title}`}
                                              </span>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </li>
                                )
                              )}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
