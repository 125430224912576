import i18next from "i18next";
import { useMemo } from "react";
import { useQuery } from "react-query";

import axios from "utils/client";
import { TranslatedStandard } from "types";
import { Directive } from "pages/Backoffice/Questionaire/types";
import { useCustomStandards } from "components/CustomStandards/hooks/useCustomStandards";

const fetchStandardTranslations = async (
  locale: string = "de",
  directive: Directive
) => {
  const langCodeQueryParam = locale.split("-")[0].toUpperCase();

  const { data } = await axios.get<{ standards: TranslatedStandard[] }>(
    // TODO replace with env param once infrastructure for crawler & DDB is set up
    `${process.env.REACT_APP_DATA_URL}/standards?lang=${langCodeQueryParam}&dir=${directive}`
  );

  return data.standards || [];
};

export const useTranslatedStandards = (
  directive: Directive = Directive.mrl
) => {
  const { language } = i18next;
  const locale = language?.split("-")[0];

  const { data: translations } = useQuery(
    ["standards", locale, directive],
    () => fetchStandardTranslations(locale, directive)
  );

  return { standards: translations, translations };
};

export const useAllTranslatedStandards = () => {
  const { language } = i18next;
  const locale = language?.split("-")[0];
  const { customStandards } = useCustomStandards();
  const { isLoading: translationsMrlLoading, data: translationsMrl } = useQuery(
    ["standards", locale, Directive.mrl],
    () => fetchStandardTranslations(locale, Directive.mrl)
  );

  const { isLoading: translationsLvdLoading, data: translationsLvd } = useQuery(
    ["standards", locale, Directive.lvd],
    () => fetchStandardTranslations(locale, Directive.lvd)
  );

  const { isLoading: translationsEmvLoading, data: translationsEmv } = useQuery(
    ["standards", locale, Directive.emv],
    () => fetchStandardTranslations(locale, Directive.emv)
  );

  const { isLoading: translationsPedLoading, data: translationsPed } = useQuery(
    ["standards", locale, Directive.pressureequip],
    () => fetchStandardTranslations(locale, Directive.pressureequip)
  );

  const mappedCustomStandards = useMemo(
    () =>
      customStandards.map((customStandard) => ({
        id: customStandard.crn,
        name: customStandard.standard_id,
        type: "",
        latest: "",
        versions: [],
        retracted: false,
        content: customStandard.title,
        lastRecorded: new Date().toISOString(),
        directive: customStandard.directive as Directive,
      })),
    [customStandards]
  );

  const translations = useMemo(
    () => [
      ...(translationsMrl || []),
      ...(translationsLvd || []),
      ...(translationsEmv || []),
      ...(translationsPed || []),
      ...mappedCustomStandards,
    ],
    [
      translationsMrl,
      translationsLvd,
      translationsEmv,
      translationsPed,
      mappedCustomStandards,
    ]
  );

  const isLoading =
    translationsMrlLoading ||
    translationsLvdLoading ||
    translationsEmvLoading ||
    translationsPedLoading;

  return { standards: translations, translations, isLoading };
};
