import { Transforms } from "slate";
import React, { useRef } from "react";
import { useSlate } from "slate-react";
import imageCompression from "browser-image-compression";

import { CustomEditor, ImageElement } from "../../types";

export const ImageButton: React.FC = () => {
  const editor = useSlate();
  const inputRef = useRef<HTMLInputElement>(null);

  // const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const url = e.target?.result as string;
  //       insertImage(editor, url);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const options = {
      maxSizeMB: 1, // Maximum size in MB
      maxWidthOrHeight: 800, // Resize to max width or height of 800px
      useWebWorker: true, // Use web workers for improved performance
    };

    try {
      const compressed = await imageCompression(file, options);

      if (compressed) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const url = e.target?.result as string;
          insertImage(editor, url);
        };

        reader.readAsDataURL(compressed);
      }
      // setCompressedFile(compressed);
      // Now you can upload `compressed` to your server.
    } catch (error) {
      console.error("Image compression error:", error);
    }
  };

  const insertImage = (editor: CustomEditor, url: string) => {
    const image: ImageElement = {
      type: "image",
      url,
      children: [{ text: "" }],
    };
    Transforms.insertNodes(editor, image);
  };

  return (
    <>
      <button
        type="button"
        className="p-2 text-gray-600 hover:bg-gray-100 rounded"
        onMouseDown={(event) => {
          event.preventDefault();
          inputRef.current?.click();
        }}
        title="Insert Image"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-6 w-6 text-gray-600"
        >
          <path d="M12 9a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 9Z" />
          <path
            fillRule="evenodd"
            d="M9.344 3.071a49.52 49.52 0 0 1 5.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 0 1-3 3h-15a3 3 0 0 1-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 0 0 1.11-.71l.822-1.315a2.942 2.942 0 0 1 2.332-1.39ZM6.75 12.75a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0Zm12-1.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <input
        type="file"
        ref={inputRef}
        onChange={handleImageUpload}
        accept="image/*"
        className="hidden"
      />
    </>
  );
};
