import { Project } from "types";
import { DocType, PDFStatus } from "../types";
import {
  requiredDocsMapping,
  requiredExitOnlyDocs,
} from "../data/requiredDocsMapping";
import { usableDocs } from "../data/docDefinitions";

export interface DocumentDefinition {
  label: string;
  type: DocType;
  revision: string;
  status: PDFStatus;
  visibility: string;
  location: string | undefined;
}

export const useEnhancedDocuments = (
  project?: Project
): DocumentDefinition[] => {
  if (!project) {
    return [];
  }

  const requiredDocs = requiredDocsMapping[project.projectdata.category];
  const exitOnlyDocs = project.directives
    .filter((d) => d.is_exit)
    .map((d) => `${requiredExitOnlyDocs[d.name]}_${d.name}` as DocType);

  const docsIncludingNulls = [...requiredDocs, ...exitOnlyDocs].map((doc) => {
    const existingDoc = project.documents?.find(({ type }) => type === doc);

    if (!usableDocs[doc]) {
      return undefined;
    }

    const { completeFn, ...docSettings } = usableDocs[doc];

    const canRender = completeFn(project);
    const status = existingDoc
      ? PDFStatus.ready
      : canRender
      ? PDFStatus.pending
      : PDFStatus.missingData;

    return {
      ...docSettings,
      status,
      location: existingDoc?.location,
      revision: existingDoc?.version || "-",
    };
  });

  return docsIncludingNulls.filter((d): d is DocumentDefinition => Boolean(d));
};
