import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import TextInput from "components/inputs/TextInput";
import { PrimaryButton } from "components/Button/PrimaryButton";
import { useCreateCustomStandard } from "./hooks/useCreateCustomStandard";

interface CreateCustomStandardOnDashboardProps {
  button: React.FunctionComponent<React.ButtonHTMLAttributes<any>>;
  onCreated?: () => void;
  initialValues?: {
    title?: string;
    directive?: string;
    standard_id: string;
  };
}

interface CustomStandardPayload {
  title?: string;
  directive?: string;
  standard_id: string;
}

export const CreateCustomStandardOnDashboard: React.FunctionComponent<
  CreateCustomStandardOnDashboardProps
> = (props) => {
  const { t } = useTranslation();
  const { mutate: createCustomStandard } = useCreateCustomStandard();

  const [payload, setPayload] = React.useState<CustomStandardPayload>({
    title: "",
    directive: "",
    standard_id: "",
  });

  React.useEffect(() => {
    if (props.initialValues) {
      setPayload(props.initialValues);
    }
  }, [props.initialValues]);
  const [modalVisible, setModalVisible] = React.useState(false);

  const handleInputChange =
    (field: keyof CustomStandardPayload) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPayload((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    };

  const handleSubmit = () => {
    if (payload.standard_id) {
      createCustomStandard(payload);
      setModalVisible(false);
      setPayload({ title: "", standard_id: "", directive: "" });

      props.onCreated && props.onCreated();
    }
  };

  return (
    <>
      <div className="mt-4">
        <props.button onClick={() => setModalVisible(true)} />
      </div>

      <Modal
        onClose={() => setModalVisible(false)}
        show={modalVisible}
        width="1/2"
        content={
          <div>
            <span className="text-xl">{t("customStandard.create_new")}</span>
            <div className="pt-4 space-y-4">
              <TextInput
                id="standard-id"
                name="standard-id"
                required
                label={t("customStandard.standardId")}
                defaultValue={payload.standard_id}
                uncontrolled
                // @ts-ignore
                onChange={handleInputChange("standard_id")}
              />
              <TextInput
                id="standard-title"
                name="standard-title"
                label={t("customStandard.title")}
                defaultValue={payload.title}
                uncontrolled
                // @ts-ignore
                onChange={handleInputChange("title")}
              />
              <TextInput
                id="standard-directive"
                name="standard-directive"
                label={t("customStandard.directive")}
                defaultValue={payload.directive}
                uncontrolled
                // @ts-ignore
                onChange={handleInputChange("directive")}
              />
            </div>

            <PrimaryButton
              float="right"
              disabled={!payload.standard_id}
              onClick={handleSubmit}
            >
              {t("customStandard.save")}
            </PrimaryButton>
          </div>
        }
      />
    </>
  );
};
