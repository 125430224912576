import { useQuery } from "react-query";
import client from "utils/client";
import { CustomStandard } from "types";

const fetchCustomStandard = async (crn: string) => {
  const { data } = await client.get<CustomStandard>(`/custom-standards/${crn}`);
  return data;
};

export const useCustomStandard = (crn: string) => {
  const { data: customStandard, isLoading } = useQuery(
    ["customStandard", crn],
    () => fetchCustomStandard(crn)
  );

  return {
    customStandard,
    isLoading,
  };
};
