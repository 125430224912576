import { classNames } from "utils/classNames";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserTab } from "components/Settings/Tabs/UserTab";
import { PasskeyTab } from "components/Settings/Tabs/PasskeyTab";
import { TeamTab } from "components/Settings/Tabs/TeamTab";
import { useAccount } from "./hooks/useAccount";
import { Feature, useFeatureFlags } from "context/FeatureFlag";

interface Tab {
  name: string;
  content: () => ReactElement;
}

const publicTabs: Tab[] = [
  {
    name: "settings.tab.user",
    content: () => <UserTab />,
  },
  {
    name: "settings.tab.security",
    content: () => <PasskeyTab />,
  },
];

const adminTabs: Tab[] = [
  {
    name: "settings.tab.user",
    content: () => <UserTab />,
  },
  // {
  //   name: "Billing",
  //   content: () => <BillingTab />,
  // },
  {
    name: "settings.tab.security",
    content: () => <PasskeyTab />,
  },
  {
    name: "settings.tab.team",
    content: () => <TeamTab />,
  },
];

const SettingTabsHeader = (props: {
  currentTab: Tab;
  tabs: Tab[];
  onChange: (tab: Tab) => void;
}) => {
  const { t } = useTranslation();
  const changeHandler = (tab: Tab) => {
    props.onChange(tab);
  };

  return (
    <div className="mt-6 sm:mt-2 2xl:mt-5">
      <div className="border-b border-gray-200 mb-10">
        <div className="mx-auto">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {props.tabs.map((tab) => (
              <span
                onClick={() => changeHandler(tab)}
                key={tab.name}
                className={classNames(
                  props.currentTab?.name === tab.name
                    ? "border-cyan-600 text-gray-900"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
              >
                {t(tab.name)}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

interface TabProps {}

export const SettingTabs = (props: TabProps) => {
  const { account, isLoading } = useAccount();
  const { isFeatureEnabled } = useFeatureFlags();
  const [tabs, setTabs] = useState<Tab[]>(publicTabs);
  const [currentTab, setCurrentTab] = useState<Tab>(publicTabs[0]);

  useEffect(() => {
    if (!isLoading && account) {
      setTabs(adminTabs);
    }
  }, [account, isLoading, isFeatureEnabled]);

  return (
    <div>
      <SettingTabsHeader
        tabs={tabs}
        currentTab={currentTab}
        onChange={(tab: Tab) => setCurrentTab(tab)}
      />
      {currentTab.content()}
    </div>
  );
};
